import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  getDaysInMonth,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  isWeekend,
  setDate,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { EventsByHourInterface } from '../stores/reportStore';

export const isToday = (dateTime: number) => {
  const someDate: Date = new Date(dateTime);
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export function getInfoTime(dateTime: number) {
  const date = new Date(dateTime);
  const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

  return `${hour}:${minutes}:${date.getSeconds()}`;
}

export function getInfoDate(dateTime: number) {
  const date = new Date(dateTime);
  let result = '';
  result = isToday(date.getTime()) ? 'Today, ' : '';
  result += `${date.getDate()} ${date.toLocaleDateString('en-US', {
    month: 'long',
  })} ${date.getFullYear()} `;
  return result;
}

export interface IDay {
  label: Number | String;
  date: Date;
  isWeekend: boolean;
  isSelected?: boolean;
  isFirstInRange?: boolean;
  isLastInRange?: boolean;
  hasEvents?: boolean;
}

export interface IDateRange {
  startDate: Date | number;
  endDate: Date | number;
  key?: String;
}

export function getMonthDaysArray(
  date: Date,
  { startDate, endDate },
  events?: EventsByHourInterface[]
): Array<IDay> {
  const daysCount = getDaysInMonth(date);
  const daysArray = [...Array(daysCount).keys()];

  // events?.forEach((e) => {
  //   console.log(format(e.hour, 'dd'));
  // });

  const eventsDay = events ? events.map((e) => format(e.hour, 'dd')) : [];

  const days = daysArray.map((index) => {
    const dayNumber = index + 1;
    const dayDate = startOfDay(setDate(date, dayNumber));
    return {
      date: dayDate,
      isWeekend: isWeekend(dayDate),
      label: dayNumber,
    };
  });

  return mapDaysArrayByRange(days, { startDate, endDate }, eventsDay);
}

export function mapDaysArrayByRange(
  array: Array<IDay>,
  { startDate, endDate },
  eventsDay: string[]
) {
  return array.map((day) => {
    const dayStart = startOfDay(day.date);
    const dayEnd = endOfDay(day.date);

    return {
      ...day,
      isSelected:
        isAfter(dayEnd, startDate) && isBefore(dayStart, endOfDay(endDate)),
      isFirstInRange: isEqual(startDate, dayStart),
      isLastInRange: isEqual(startOfDay(endDate), dayStart),
      hasEvents: eventsDay.some((eventDay) => {
        return parseInt(eventDay) === day.label;
      }),
    };
  });
}

export function getMTD(date: Date) {
  return {
    startDate: startOfMonth(date),
    endDate: date,
    key: 'MTD selection',
  };
}

export function getYTD(date: Date) {
  return {
    startDate: startOfYear(date),
    endDate: date,
    key: 'YTD selection',
  };
}

const ranges = [
  {
    label: 'Today',
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
  },
  {
    label: 'Yesterday',
    startDate: startOfDay(addDays(new Date(), -1)),
    endDate: endOfDay(addDays(new Date(), -1)),
  },

  {
    label: 'This Week',
    startDate: startOfWeek(new Date()),
    endDate: endOfWeek(new Date()),
  },
  {
    label: 'Last Week',
    startDate: startOfWeek(addDays(new Date(), -7)),
    endDate: endOfWeek(addDays(new Date(), -7)),
  },
  {
    label: 'This Month',
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  },
  {
    label: 'Last Month',
    startDate: startOfMonth(addMonths(new Date(), -1)),
    endDate: endOfMonth(addMonths(new Date(), -1)),
  },
];

export function humanizeDateRange(from, to) {
  const range = ranges.find(
    (range) => isSameDay(range.startDate, from) && isSameDay(range.endDate, to)
  );

  return range
    ? range.label
    : `${format(from, 'MMM dd, yyyy')} - ${format(to, 'MMM dd, yyyy')}`;
}
