import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Create styles
export const CameraCardStyles = makeStyles({
  camera: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'row',
  },
  cameraImage: {
    width: 256,
    height: 150,
    borderRadius: 8,
    objectFit: 'cover',
  },
  plug: {
    border: '1px solid black',
  },
  title: {
    display: 'flex',
  },
  cameraName: {
    fontWeight: 'bold',
    fontSize: 24,
    marginRight: 15,
  },
  companyName: {
    fontSize: 24,
  },
  info: {
    flexDirection: 'column',
    flexGrow: 1,
  },
  infoText: {
    marginTop: 23,
  },
  text: {
    fontSize: 18,
  },
  highlightedText: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: 18,
    marginRight: 6,
  },
});

export const CameraCard = ({
  thumbnailBase64,
  workingHours,
  events,
  cameraName,
  companyName,
  uptime,
  imgIsVisible,
}) => {
  const classes = CameraCardStyles({});
  return (
    <Box className={classes.camera}>
      {imgIsVisible && (
        <>
          {thumbnailBase64 ? (
            <img
              src={thumbnailBase64}
              alt='camera'
              className={classes.cameraImage}
            />
          ) : (
            <div className={`${classes.cameraImage} ${classes.plug}`} />
          )}
        </>
      )}

      <Box className={classes.info} ml={imgIsVisible ? 4 : 0}>
        <Box className={classes.title}>
          <span className={classes.cameraName}>{cameraName}</span>
          <span className={classes.companyName}>{companyName}</span>
        </Box>
        <Box className={classes.infoText}>
          <Box>
            <span className={classes.highlightedText}>{`${uptime}%`}</span>
            <span
              className={classes.text}
            >{`uptime (${workingHours} h working)`}</span>
          </Box>
          <Box>
            <span className={classes.highlightedText}>{events}</span>
            <span className={classes.text}>events</span>
          </Box>
          <Box>
            <span className={classes.highlightedText}>{2}</span>
            <span className={classes.text}>video verified alarms</span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
