import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import compose from "ramda/src/compose";
import clsx from "clsx";
// Material UI imports
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {
  ThemeProvider,
  makeStyles,
  createStyles,
} from "@material-ui/core/styles";
// Other relative imports
import { Volume } from "./Volume";
import { Microphone } from "./Microphone";
import { SoundsList } from "./SoundsList";
import { BehaviorsList } from "./BehaviorsList";
import { Lights } from "./Lights";
import { Camera } from "./Camera";
import { EventDetection } from "./EventDetection";
import { theme } from "../../services/theme";
import { ReqStatus } from "../../types/ReqStatus";
import { IFilesStore } from "../../stores/FilesStore";
import { ICameraSettingsStore } from "../../stores/СameraSettingsStore/index";
import { convertLightStateToBool } from "../../stores/СameraSettingsStore/models/LightsSettings";
import { NioMesh } from "./NioMesh";

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      width: "300px",
      height: "360px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    container: {
      width: "100%",
      height: "100%",
      position: "relative",
      overflowX: "hidden",
    },
    content: {
      width: "100%",
      height: "100%",
      position: "absolute",
      transition: "left 0.3s ease-in",
    },
    main: {
      left: "-100%",
    },
    secondary: {
      left: "100%",
      display: "flex",
      flexDirection: "column",
    },
    active: {
      left: "0%",
    },
    backNav: {
      display: "flex",
      alignItems: "center",
      marginBottom: "0.35em",
    },
  })
);

export interface ISettingsDialogProps
  extends Pick<DialogProps, "onClose" | "open"> {
  cameraSettingsStore: ICameraSettingsStore;
  filesStore: IFilesStore;
  thumbnailUrl: string;
}

type ViewId =
  | "Main"
  | "1stVoiceDown"
  | "AlertedVoiceDown"
  | "Lights"
  | "Camera"
  | "NioBehavior"
  | "EventDetection";

const renderMainContent = (audio, script, setView) => (
  <List>
    <ListItem divider>
      <Volume
        value={audio.audioVolume}
        onChange={(_, val) => audio.setVolume(val)}
      />
    </ListItem>

    <ListItem onClick={() => setView("1stVoiceDown")} button divider>
      <ListItemText
        primary={<Typography variant="body2">1st Voice Down</Typography>}
      />
    </ListItem>

    <ListItem onClick={() => setView("AlertedVoiceDown")} button divider>
      <ListItemText
        primary={<Typography variant="body2">Alerted Voice Down</Typography>}
      />
    </ListItem>

    <ListItem divider>
      <Microphone
        isMute={audio.micMute}
        onMuteChange={() => audio.toggleMicMute()}
      />
    </ListItem>

    <ListItem onClick={() => setView("Lights")} button divider>
      <ListItemText primary={<Typography variant="body2">Lights</Typography>} />
    </ListItem>

    <ListItem onClick={() => setView("EventDetection")} button divider>
      <ListItemText
        primary={<Typography variant="body2">Event Detection</Typography>}
      />
    </ListItem>

    <ListItem divider>
      <NioMesh
        isEnabled={script.allCameraLightsOn}
        onNioMeshChange={() => script.toggleNioMesh()}
      />
    </ListItem>

    <ListItem onClick={() => setView("Camera")} button divider>
      <ListItemText primary={<Typography variant="body2">Camera</Typography>} />
    </ListItem>

    <ListItem onClick={() => setView("NioBehavior")} button>
      <ListItemText
        primary={<Typography variant="body2">Niō Behavior</Typography>}
      />
    </ListItem>
  </List>
);

const renderSecondaryContent = (
  view: ViewId,
  cameraSettingsStore: ICameraSettingsStore,
  filesStore: IFilesStore,
  thumbnailUrl: string
) => {
  const { script, lights, motion, ai, recordings } =
    cameraSettingsStore.desired;

  if (view === "1stVoiceDown") {
    return (
      <SoundsList
        selected={script.alertSoundFiles.warning2}
        filesStore={filesStore}
        onChange={script.setFirstVoiceDown}
      />
    );
  }

  if (view === "AlertedVoiceDown") {
    return (
      <SoundsList
        selected={script.alertSoundFiles.warning1}
        filesStore={filesStore}
        onChange={script.setAlertedVoiceDown}
      />
    );
  }

  if (view === "Lights") {
    return (
      <Lights
        topLights={convertLightStateToBool(lights.ambientTop.state)}
        bottomLights={convertLightStateToBool(lights.ambientBottom.state)}
        brightness={lights.ambientTop.luminance}
        colorTemperature={lights.ambientTop.colorTemperature}
        onTopLightsChange={lights.toggleTopLights}
        onBottomLightsChange={lights.toggleBottomLights}
        onBrightnessChange={lights.setBrightness}
        onColorTemperatureChange={lights.setColorTemperature}
      />
    );
  }

  if (view === "EventDetection") {
    return (
      <EventDetection
        thumbnailUrl={thumbnailUrl}
        pir={motion.pir.sensitivity}
        onPirChange={motion.setPirSensitivity}
        vehicleTiles={ai.aoiV2.vehicleTiles}
        personTiles={ai.aoiV2.personTiles}
        onVehicleTilesChange={ai.aoiV2.setVehicleTiles}
        onPersonTilesChange={ai.aoiV2.setPersonTiles}
      />
    );
  }

  if (view === "Camera") {
    return (
      <Camera
        quality={!!recordings.resolution}
        nightVision={!!script.nightVision}
        hdr={recordings.hdrEnabled}
        onQualityChange={recordings.toggleVideoQuality}
        onNightVisionChange={script.togleNightVision}
        onHdrChange={recordings.toggleHdr}
      />
    );
  }

  if (view === "NioBehavior") {
    return (
      <BehaviorsList
        selected={script.profileFile}
        filesStore={filesStore}
        onChange={script.setProfileFile}
      />
    );
  }

  return null;
};

export const SettingsDialog = ({
  cameraSettingsStore,
  filesStore,
  thumbnailUrl,
  open,
  onClose,
}: ISettingsDialogProps) => {
  const classes = useStyles();
  const [view, setView] = useState<ViewId>("Main");
  const { audio, script } = cameraSettingsStore.desired;

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          setView("Main");
          onClose?.(event, reason);
        }}
      >
        <DialogTitle>
          {cameraSettingsStore.reqStatus !== ReqStatus.Loading &&
            cameraSettingsStore.serialNumber}
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {cameraSettingsStore.reqStatus === ReqStatus.Loading && (
            <CircularProgress />
          )}

          {cameraSettingsStore.reqStatus === ReqStatus.Succeeded && (
            <div className={classes.container}>
              <div
                className={clsx(classes.content, classes.main, {
                  [classes.active]: view === "Main",
                })}
              >
                {renderMainContent(audio, script, setView)}
              </div>

              <div
                className={clsx(classes.content, classes.secondary, {
                  [classes.active]: view !== "Main",
                })}
              >
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => setView("Main")}
                  className={classes.backNav}
                >
                  <ArrowBackIosIcon fontSize="small" /> Back
                </Link>
                <div>
                  {renderSecondaryContent(
                    view,
                    cameraSettingsStore,
                    filesStore,
                    thumbnailUrl
                  )}
                </div>
              </div>
            </div>
          )}
          {cameraSettingsStore.reqStatus === ReqStatus.Failed && (
            <Typography variant="body1">
              Unable to load camera settings. Please try again later.
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default compose(
  inject("cameraSettingsStore", "filesStore"),
  observer
)(SettingsDialog);
