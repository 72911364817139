import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { makeStyles } from "@material-ui/core";

import { Camera } from "./Camera";
import { data } from "./mock";

export const MapStyles = makeStyles({
  root: {
    height: "calc(100vh - 107px)",
    with: "100%",
  },
});

const cameraInfo = {
  company: "Feynman Particles and Fields",
  cameraName: "Parking Lot",
  address: "12321 Enterprise Ave.",
  scheduledService: true,
  img: "https://i.ibb.co/pWmGWdG/Video8.jpg",
  uptime: 100,
  workingHours: 715,
  intrusions: 260,
  intrusionsDeterred: 260,
  verifiedAlarms: 2,
};

const Map = () => {
  const classes = MapStyles({});

  const [zoom, setZoom] = useState(13);

  const coord = {
    center: {
      lat: 37.7995965288116,
      lng: -122.39849538310354,
    },
    zoom: 18,
  };

  const handleMapChange = (e) => {
    setZoom(e.zoom);
  };

  return (
    <div className={classes.root}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
        defaultCenter={coord.center}
        defaultZoom={coord.zoom}
        options={{
          mapTypeId: "satellite",
        }}
        onChange={handleMapChange}
        hoverDistance={50}
      >
        {data.map((meta, i) => (
          <Camera
            {...meta}
            key={i}
            zoom={zoom}
            cameraInfo={cameraInfo}
            index={i}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
