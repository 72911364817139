import { types, flow, getEnv, Instance, cast } from "mobx-state-tree";
import { File } from "./models/File";
import { Environment } from "../Environment";
import { ReqStatus } from "../../types/ReqStatus";

export const FilesStore = types
  .model("FilesStore", {
    reqStatus: types.optional(
      types.enumeration<ReqStatus>(Object.values(ReqStatus)),
      ReqStatus.Idle
    ),
    audioFiles: types.optional(types.array(File), []),
    profileFiles: types.optional(types.array(File), []),
  })
  .actions((self) => {
    const { api } = getEnv<Environment>(self);

    return {
      getAudios: flow(function* (companyId: string) {
        self.reqStatus = ReqStatus.Loading;

        const response = yield api.getAudiosMock(companyId);
        debugger;
        self.reqStatus = ReqStatus.Succeeded;

        const { alertSoundFiles = [] } = response || {};
        self.audioFiles = cast(alertSoundFiles);
      }),
      getScripts: flow(function* (companyId: string) {
        self.reqStatus = ReqStatus.Loading;

        const response = yield api.getScriptsMock(companyId);
        self.reqStatus = ReqStatus.Succeeded;

        const { profileFiles } = response || {};
        self.profileFiles = cast(profileFiles);
      }),
    };
  });

export interface IFilesStore extends Instance<typeof FilesStore> {}
