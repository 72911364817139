import React from "react";
import { Grid } from "@material-ui/core";
import CamerasListItem from "../CamerasListItem";
import { CameraInterface } from "../../stores/cameraStore";
import { FilterStoreInterface } from "../../stores/filterStore";
import { ICameraSettingsStore } from "../../stores/СameraSettingsStore";
import { IFilesStore } from "../../stores/FilesStore";
import { inject, observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface Props extends RouteComponentProps {
  filterStore?: FilterStoreInterface;
  cameraSettingsStore?: ICameraSettingsStore;
  filesStore?: IFilesStore;
}

@inject("filterStore", "cameraSettingsStore", "filesStore")
@observer
class CamerasList extends React.Component<Props> {
  render() {
    const { filterStore, cameraSettingsStore, filesStore } = this.props;

    return (
      <Grid container>
        <Grid container spacing={3} item>
          {filterStore && filterStore.cameras.map(
            (camera: CameraInterface, i: number) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={camera.serialNumber}>
                  <CamerasListItem
                    camera={camera}
                    idx={i}
                    onSettingsIconClick={() => {
                      if (camera.companyId) {
                        cameraSettingsStore?.getCameraSettings(
                          camera.companyId,
                          camera.serialNumber
                        );
                        filesStore?.getAudios(camera.companyId);
                        filesStore?.getScripts(camera.companyId);
                      }
                    }}
                  />
                </Grid>
              );
            }
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(CamerasList);
