import { flow, getRoot, Instance, types } from 'mobx-state-tree';
import { RootStoreInterface } from './RootStore';
import { withEnvironment } from './withEnvironment';
import Amplify, { Auth } from 'aws-amplify';
import {
  AUTH_USER_COMPANY_NAME,
  AUTH_USER_TOKEN_KEY,
  DESIRED_PATH_LOCAL_STORE_KEY,
  POOL_INFO_KEY
} from '../services/constants';
import { parseJwt } from '../services/jwt';
import { Company } from './companyStore';
import config from '../config';

const poolsUrl = process.env.REACT_APP_POOL_URL || '/pools/';

const configureAmplify = (poolInfo) => {
  // console.log(poolInfo);
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID || poolInfo.identityPoolId,
      region: process.env.REACT_APP_REGION || poolInfo.region,
      userPoolId: process.env.REACT_APP_USER_POOL_ID || poolInfo.userPoolId,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || poolInfo.webClientId,
    },
  });
};

export const AuthError = types
  .model('AuthError', {
    statusCode: types.optional(types.string, ''),
    code: types.optional(types.string, ''),
    message: types.optional(types.string, ''),
  });

export const PoolInfo = types
  .model('PoolInfo', {
    identityPoolId: types.optional(types.string, ''),
    userPoolId: types.optional(types.string, ''),
    webClientId: types.optional(types.string, ''),
    region: types.optional(types.string, 'us-east-1'),
  });

let poolInfoString = null;
try {
  poolInfoString = JSON.parse(localStorage.getItem(POOL_INFO_KEY) || '') || null;
} catch (e) {
}

export const AuthStore = types
  .model('AuthStore', {
    inProgress: types.optional(types.boolean, false),
    errors: types.maybeNull(AuthError),
    company: types.optional(types.string, localStorage.getItem(AUTH_USER_COMPANY_NAME) || ''),
    email: types.optional(types.string, ''),
    username: types.optional(types.string, ''),
    password: types.optional(types.string, ''),
    code: types.optional(types.string, ''),
    message: types.optional(types.string, ''),
    roles: types.optional(types.string, ''),
    currentUser: types.maybeNull(types.string),
    spId: types.optional(types.string, ''),
    companyId: types.optional(types.string, ''),
    poolInfo: types.optional(types.maybeNull(PoolInfo), poolInfoString),
    currentCompany: types.optional(types.maybeNull(Company), undefined),
  })
  .extend(withEnvironment)
  .views(self => ({
    get isSPUser() {
      return self.spId && self.spId === self.companyId;
    }
  }))
  .actions(self => ({
    setCurrentUser(user) {
      self.currentUser = user;

      try {
        if (user) {
          const parsedJwt = parseJwt(user);
          self.username = parsedJwt['cognito:username']
          self.spId = parsedJwt['custom:sp_id']
          self.companyId = parsedJwt['custom:company_id']
          self.roles = parsedJwt['cognito:groups'].join(', ')
        }
      } catch (e) {
        console.error(e);
      }
    },
    setCode(code) {
      self.code = code;
    },
    setEmail(email) {
      self.email = email;
    },
    setCompany(company) {
      self.company = company;
      localStorage.setItem(AUTH_USER_COMPANY_NAME, company || '');
    },
    setPassword(password) {
      self.password = password;
    },
    setMessage(message) {
      self.message = message;
    },
    reset() {
      self.errors = null;
      self.message = '';
      self.email = '';
      self.password = '';
      self.code = '';
    },
    simpleErr(err) {
      return {
        statusCode: err.statusCode,
        code: err.code,
        message: err.message,
      };
    }
  }))
  .actions(self => ({
    verifySession: flow(function* () {
      if (!self.poolInfo) {
        getRoot<RootStoreInterface>(self).setAppLoaded();
        return false;
      }

      configureAmplify(self.poolInfo);

      try {
        const user = yield Auth.currentSession();
        const idToken = user.getIdToken();
        const jwtToken = idToken.getJwtToken();
        self.setCurrentUser(jwtToken);
        localStorage.setItem(AUTH_USER_TOKEN_KEY, jwtToken);
        self.setEmail(idToken.payload['cognito:username']);
      } catch (err) {
        console.log(err);
      }
      getRoot<RootStoreInterface>(self).setAppLoaded();
      return !!self.currentUser;
    }),
    login: flow(function* (email, password) {
      self.inProgress = true;
      self.errors = null;

      configureAmplify(self.poolInfo);
      try {
        const user = yield Auth.signIn(email, password);
        const token = user.getSignInUserSession().getIdToken().getJwtToken();
        self.setCurrentUser(token);
        localStorage.setItem(AUTH_USER_TOKEN_KEY, token);
        return true;
      } catch (err) {
        console.log(err);
      }
      self.inProgress = false;
      return false;
    }),
    register: flow(function* (email: string, password: string, name: string, phoneNumber: string) {
      self.inProgress = true;
      self.errors = null;

      configureAmplify(self.poolInfo);

      try {
        yield Auth.signUp({
          username: email,
          password,
          attributes: {
            email,
            name,
            phone_number: phoneNumber
          }
        });
      } catch (err) {
        console.log(err);
        self.errors = self.simpleErr(err);
      }

      self.inProgress = false;
    }),
    confirmCode: flow(function* (confirmationCode) {
      self.inProgress = true;
      self.errors = null;

      try {
        yield Auth.confirmSignUp(self.email, confirmationCode);
      } catch (err) {
        console.log(err);
        self.errors = self.simpleErr(err);
      }

      self.inProgress = false;
    }),
    logout: flow(function* () {
      try {
        yield Auth.signOut();
        self.setCurrentUser(null);
        localStorage.clear();
      } catch (err) {
        console.log(err);
      }
    }),
    forgotPassword: flow(function* (email) {
      self.inProgress = true;
      self.errors = null;

      configureAmplify(self.poolInfo);

      try {
        yield Auth.forgotPassword(email);
      } catch (err) {
        console.log(err);
        self.errors = self.simpleErr(err);
      }

      self.inProgress = false;
    }),
    forgotPasswordConfirm: flow(function* (email: string, code: string, password: string) {
      self.inProgress = true;
      self.errors = null;

      configureAmplify(self.poolInfo);

      try {
        yield Auth.forgotPasswordSubmit(email.trim(), code.trim(), password.trim());
      } catch (err) {
        console.log(err);
        self.errors = self.simpleErr(err);
      }

      self.inProgress = false;
    }),
    setPoolInfo: function (poolInfo) {
      self.poolInfo = poolInfo;
      localStorage.setItem(POOL_INFO_KEY, JSON.stringify(poolInfo));
    },
    findCompanyData: flow(function* (companyName) {
      const response = yield self.environment.api.client.get(`${poolsUrl}?company_name=${companyName}`);

      if (response.data) {
        return response.data[0];
      }
    }),
    getCurrentCompany: flow(function* () {
      const response = yield self.environment.api.client.get(`v1/companies/current`);

      if (response.ok) {
        self.currentCompany = response.data;
        return response.data;
      }
    }),
    getCurrentCompanyMock: flow(function* () {
      const response = yield self.environment.api.client.get(`${config.ROOT_PATH}json/current.json`);

      if (response.ok) {
        self.currentCompany = response.data;
        return response.data;
      }
    }),
    saveDesiredPath: (path: string, search: string) => {
      if (path !== '/' && path !== '/login') {
        localStorage.setItem(
          DESIRED_PATH_LOCAL_STORE_KEY,
          JSON.stringify({
            path,
            search,
          })
        );
      }
    },
    getDesiredPath: () => {
      const path = localStorage.getItem(DESIRED_PATH_LOCAL_STORE_KEY) || '{}';
      localStorage.removeItem(DESIRED_PATH_LOCAL_STORE_KEY);
      return JSON.parse(path);
    }
  }))
;

export interface AuthStoreInterface extends Instance<typeof AuthStore> {
}
