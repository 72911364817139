import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { DateRangePicker } from 'react-date-range';
import { isBefore } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
// import { DateRange } from '@material-ui/icons';
import {
  humanizeDateRange,
  getMonthDaysArray,
  IDay,
  getYTD,
  getMTD,
} from '../../services/date';
import { useStores } from '../../stores/RootStore';

export const PanelDateRangeStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '14px 22px 17px',
    color: 'rgba(255,255,255,0.6)',
    letterSpacing: '-0.456px',
    [theme.breakpoints.down('md')]: {
      padding: '14px 0 17px 12px',
    },
  },
  head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  text: {
    fontFamily: 'Montserrat, Helvetica Neue, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '15px',
    color: 'rgba(255,255,255,0.6)',
  },
  monthLabel: {
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '19px',
    color: '#FFFFFF',
  },
  yearLabel: {
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '19px',
    color: '#FFFFFF',
  },
  day: {
    position: 'relative',
    width: 24,
    height: 24,
    [theme.breakpoints.down('md')]: {
      width: 20,
      height: 20,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Montserrat, Helvetica Neue, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '15px',
    cursor: 'pointer',

    '&.is-selected': {
      backgroundColor: '#FFFFFF',
      color: '#2E2E2E',
      '&:first-of-type': {
        borderTopLeftRadius: 24,
        borderBottomLeftRadius: 24,
      },
      '&:last-of-type': {
        borderTopRightRadius: 24,
        borderBottomRightRadius: 24,
      },
    },
    '&.is-first': {
      borderTopLeftRadius: 24,
      borderBottomLeftRadius: 24,
    },
    '&.is-last': {
      borderTopRightRadius: 24,
      borderBottomRightRadius: 24,
    },
    '&.is-hovered': {
      '&:not(.is-selected)': {
        borderTop: '1px solid #fff',
        borderBottom: '1px solid #fff',
        '&.is-hovered-first': {
          borderLeft: '1px solid #fff',
          borderRadius: '24px 0 0 24px',
        },
        '&.is-hovered-last': {
          borderRight: '1px solid #fff',
          borderRadius: '0 24px 24px 0',
          '&.is-hovered-first': {
            borderRadius: 24,
          },
        },
      },
      '&.is-selected': {
        '&:before, &:after': {
          content: '""',
          height: 1,
          width: '50%',
          position: 'absolute',
          backgroundColor: '#fff',
        },
        '&:before': {
          top: 0,
        },
        '&:after': {
          bottom: 0,
        },
        '&.is-hovered-first': {
          '&:before, &:after': {
            left: '50%',
          },
        },
        '&.is-hovered-last': {
          '&:before, &:after': {
            left: 0,
          },
          '&.is-hovered-first': {
            '&:before, &:after': {
              opacity: 0,
            },
          },
        },
      },
    },
    '&.has-events': {
      // borderBottom:
    },
  },
  hasEvents: {
    '&:after': {
      content: "''",
      width: 8,
      height: 2,
      borderRadius: 1,
      backgroundColor: '#FF0046',
      position: 'absolute',
      top: '86%',
      left: '37%',
    },
  },
  dayWeekend: {
    fontWeight: 900,
  },
  button: {
    padding: `0px ${theme.spacing(1)}px`,
  },
  dateRange: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '100%',
    zIndex: 100,
  },
}));

function PanelDateRange(props) {
  const classes = PanelDateRangeStyles(props);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const rangeWrapper = useRef<HTMLDivElement>(null);
  const { filterStore, reportStore } = useStores();
  const { loadStatsMock, stats } = reportStore;

  const [rangeOpen, setRangeOpen] = useState(false);
  const [dayClicked, setDayClicked] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: filterStore.startDate,
      endDate: filterStore.endDate,
      key: 'selection',
    },
  ]);

  const today = new Date();
  const year = today.getFullYear();
  const month = today.toLocaleDateString('en-US', { month: 'long' });
  const daysArray = getMonthDaysArray(today, range[0], stats?.events);

  useEffect(() => {
    loadStatsMock();
  }, [loadStatsMock]);

  const handleClickOutside = (e) => {
    if (rangeWrapper.current && rangeWrapper.current.contains(e.target)) {
      // inside click
      return;
    }

    setRangeOpen(false);
  };

  useEffect(() => {
    // add when mounted
    if (rangeOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [rangeOpen]);

  const onDateChange = (item) => {
    setRange([item.selection]);
  };

  const onApplyRange = () => {
    setRangeOpen(false);

    filterStore.setRange(range[0].startDate, range[0].endDate);
  };

  const changeRange = (range) => {
    setRange([range]);
    filterStore.setRange(range.startDate, range.endDate);
  };

  const onClickMTD = () => {
    const range = getMTD(today);
    changeRange(range);
  };

  const onClickYTD = () => {
    const range = getYTD(today);
    changeRange(range);
  };

  const selectDay = (day) => {
    let { startDate, endDate } = range[0];

    if (dayClicked) {
      if (isBefore(day.date, startDate)) {
        startDate = day.date;
      } else {
        endDate = day.date;
      }

      setDayClicked(false);
    } else {
      startDate = day.date;
      endDate = day.date;
      setDayClicked(true);
    }

    const newRange = {
      startDate,
      endDate,
      key: 'selection',
    };

    changeRange(newRange);
  };

  const onDayHoverEnter = (event) => {
    event.target.classList.add(
      'is-hover',
      'is-hovered',
      'is-hovered-first',
      'is-hovered-last'
    );

    if (dayClicked) {
      const items = [...event.target.parentNode.children];
      const selectedIndex = items.findIndex((item) =>
        item.classList.contains('is-selected')
      );
      const hoverIndex = items.findIndex((item) =>
        item.classList.contains('is-hover')
      );
      const firstIndex =
        selectedIndex < hoverIndex ? selectedIndex : hoverIndex;
      const lastIndex = selectedIndex > hoverIndex ? selectedIndex : hoverIndex;

      items.forEach((item, index) => {
        const isBetween = index >= firstIndex && index <= lastIndex;
        const isFirst = index === firstIndex;
        const isLast = index === lastIndex;

        item.classList.remove(
          'is-hovered',
          'is-hovered-first',
          'is-hovered-last'
        );

        if (isBetween) {
          item.classList.add('is-hovered');
          if (isFirst) item.classList.add('is-hovered-first');
          if (isLast) item.classList.add('is-hovered-last');
        }
      });
    }
  };

  const onDayHoverLeave = (event) => {
    event.target.classList.remove('is-hover');
    [...event.target.parentNode.children].forEach((item) =>
      item.classList.remove('is-hovered', 'is-hovered-first', 'is-hovered-last')
    );
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.head}>
        <Box display='flex'>
          <Typography className={classes.monthLabel}>{month}</Typography>
          <Box ml={4}>
            <Typography className={classes.yearLabel}>{year}</Typography>
          </Box>
        </Box>
        {!matches && (
          <Box display='flex'>
            <Button
              classes={{
                root: classes.button,
                label: classes.text,
              }}
              onClick={onClickMTD}
            >
              MTD
            </Button>

            <Button
              classes={{
                root: classes.button,
                label: classes.text,
              }}
              onClick={onClickYTD}
            >
              YTD
            </Button>

            <Button
              classes={{
                root: classes.button,
                label: classes.text,
              }}
              onClick={() => setRangeOpen(true)}
            >
              {humanizeDateRange(filterStore.startDate, filterStore.endDate)}
            </Button>
          </Box>
        )}
      </Box>
      <Grid container alignItems='center'>
        <Grid item>
          <Grid container alignItems='center'>
            {daysArray.map((day: IDay) => {
              return (
                <Box
                  className={clsx(classes.day, {
                    [classes.dayWeekend]: day.isWeekend,
                    'is-selected': day.isSelected,
                    'is-first': day.isFirstInRange,
                    'is-last': day.isLastInRange,
                  })}
                  key={`${day.label}`}
                  onClick={() => selectDay(day)}
                  onMouseEnter={onDayHoverEnter}
                  onMouseLeave={onDayHoverLeave}
                >
                  <span className={day.hasEvents ? classes.hasEvents : ''}>
                    {day.label}
                  </span>
                </Box>
              );
            })}
          </Grid>
        </Grid>

        {matches && (
          <Grid item>
            <Grid container>
              <Button
                classes={{
                  root: classes.button,
                  label: classes.text,
                }}
                onClick={onClickMTD}
              >
                MTD
              </Button>

              <Button
                classes={{
                  root: classes.button,
                  label: classes.text,
                }}
                onClick={onClickYTD}
              >
                YTD
              </Button>

              <Button
                classes={{
                  root: classes.button,
                  label: classes.text,
                }}
                // variant="contained"
                // color="secondary"
                // startIcon={<DateRange />}
                onClick={() => setRangeOpen(true)}
              >
                {humanizeDateRange(filterStore.startDate, filterStore.endDate)}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      {rangeOpen && (
        <div className={classes.dateRange} ref={rangeWrapper}>
          <DateRangePicker
            onChange={onDateChange}
            minimumNights={0}
            twoStepChange={true}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            maxDate={new Date()}
            months={2}
            ranges={range}
            direction='horizontal'
            showDateDisplay={false}
            inputRanges={[]}
          />
          <Button
            variant='contained'
            size='small'
            color='primary'
            onClick={onApplyRange}
          >
            Apply Range
          </Button>
        </div>
      )}
    </Box>
  );
}

export default observer(PanelDateRange);
