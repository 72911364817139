import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Button, useTheme, useMediaQuery } from '@material-ui/core';
import { useStores } from 'stores/RootStore';
import ToggleIcon from 'components/icons/IToggle';
import DashboardFilter from './PanelFilter';

export const PanelHeaderMiddleStyles = makeStyles((theme) => ({
  root: {
    padding: '21px 48px 18px',
    // marginLeft: '-24px',
    [theme.breakpoints.down('md')]: {
      padding: '16px 2px',
    },
  },
  toggleButton: {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.456px',
    color: '#007AFF',
  },
}));

function PanelHeaderMiddle(props) {
  const classes = PanelHeaderMiddleStyles(props);
  const { recordingsStore, sidebarStore } = useStores();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={matches ? 8 : 9}>
          <Box>
            <DashboardFilter
              placeholder={'Search Events and Recordings…'}
              searchChange={(newSearch) => {
                recordingsStore.searchPanel.setSearch(newSearch);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={matches ? 4 : 3}>
          <Box px={3}>
            <Button
              startIcon={<ToggleIcon />}
              className={classes.toggleButton}
              color='inherit'
              aria-label='open drawer'
              onClick={sidebarStore.toggleView}
            >
              {sidebarStore.isOpen ? 'Hide Sidebar' : 'Show Sidebar'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default observer(PanelHeaderMiddle);
