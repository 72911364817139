import React, { FC, useRef } from 'react';
import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useStores } from '../../stores/RootStore';
import { svgToPng } from '../../services/svgToPng';

// Create styles
export const PieChartStyles = makeStyles({
  container: {
    width: 205,
    position: 'relative',
  },
  absolute: {
    width: 205,
    height: 205,
    position: 'absolute',
    top: '-45px',
  },
});

interface ReportPieChartProps {
  firstChartData: object[];
  secondChartValue: number;
}

export const ReportPieChart: FC<ReportPieChartProps> = ({
  firstChartData,
  secondChartValue,
}) => {
  const classes = PieChartStyles({});
  const { reportStore } = useStores();
  const { activityCharts } = reportStore;

  const chartRef = useRef(null);

  const convertChart = async (ref) => {
    if (ref && ref.container) {
      const svg = ref.container.children[0];
      const pngData = await svgToPng(svg, 200, 200);
      activityCharts?.setReportPieChart(pngData);
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.absolute}>
        <ResponsiveContainer width={205} height='100%'>
          <PieChart width={200} height={200} ref={chartRef}>
            <defs>
              <pattern
                id='pattern'
                width='190'
                height='190'
                patternUnits='userSpaceOnUse'
              >
                <circle
                  r='65'
                  transform='translate(102,102)'
                  stroke='#00D770'
                  strokeWidth='40'
                  strokeDasharray='3 3'
                  fill='none'
                />
              </pattern>
              <pattern
                id='pattern1'
                width='210'
                height='210'
                patternUnits='userSpaceOnUse'
              >
                <circle
                  r='98'
                  transform='translate(100,100)'
                  stroke='#FF0046'
                  strokeWidth='20'
                  strokeDasharray='4 4'
                  fill='none'
                />
              </pattern>
            </defs>
            <Pie
              data={firstChartData}
              dataKey='value'
              cx='50%'
              cy='50%'
              innerRadius={45}
              outerRadius={83}
              animationDuration={0}
            >
              {firstChartData.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={'url(#pattern)'}
                  opacity={index ? 0.4 : 1}
                />
              ))}
            </Pie>
            <Pie
              data={[{ name: 'Video Hours', value: 1 }]}
              dataKey='value'
              cx='50%'
              cy='50%'
              startAngle={90}
              endAngle={secondChartValue + 90}
              innerRadius={90}
              outerRadius={102}
              fill='url(#pattern1)'
              animationDuration={0}
              onAnimationEnd={() => {
                convertChart(chartRef.current);
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};
