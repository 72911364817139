import React, { useEffect, useState } from "react";
import clsx from "clsx";
import adjust from "ramda/src/adjust";
// Material UI imports
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import {
  ThemeProvider,
  makeStyles,
  createStyles,
} from "@material-ui/core/styles";
// Other relative imports
import { theme } from "../../services/theme";

const DEFAULT_TILES = new Array<boolean>(14 * 14).fill(false);

const enrichState = (initTiles: number[]): boolean[] => {
  const result = DEFAULT_TILES.slice();
  initTiles.forEach((ac) => (result[ac] = true));
  return result;
};

const transformState = (tiles: boolean[]): number[] =>
  tiles.reduce<number[]>(
    (acc, tile, idx) => (tile ? acc.concat(idx) : acc),
    []
  );

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      maxWidth: "none",
      overflowY: "visible",
    },
    buttons: {
      width: "100%",
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "flex-end",
    },
    saveBtn: {
      marginLeft: theme.spacing(2),
    },
    container: {
      width: "1280px",
      height: "720px",
      position: "relative",
    },
    thumbnail: {
      width: "100%",
      height: "100%",
      filter: "grayscale(100%)",
      background: ({ thumbnailUrl }: { thumbnailUrl: string }) =>
        `center/100% url(${thumbnailUrl}) no-repeat`,
    },
    grid: {
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(14, 1fr)",
      gridTemplateRows: "repeat(14, 1fr)",
      borderTop: "solid 1px #FFF",
      borderLeft: "solid 1px #FFF",
    },
    cell: {
      borderBottom: "solid 1px #FFF",
      borderRight: "solid 1px #FFF",
    },
    active: {
      backgroundColor: "rgba(255, 0, 0, 0.4)",
    },
  })
);

export interface IAreaOfInterestDialogProps
  extends Pick<DialogProps, "onClose" | "open"> {
  title: string;
  thumbnailUrl: string;
  initTiles: number[];
  onSave?: (tiles: number[]) => void;
}

export const AreaOfInterestDialog = ({
  title,
  thumbnailUrl,
  initTiles = [],
  open,
  onClose,
  onSave,
}: IAreaOfInterestDialogProps) => {
  const classes = useStyles({ thumbnailUrl });
  const [tiles, setTiles] = useState(enrichState(initTiles));
  const [isSelectionInProgress, setSelectionInProgress] = useState(false);

  useEffect(() => {
    setTiles(enrichState(initTiles));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          onClose?.(event, reason);
        }}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          <div className={classes.container}>
            <div className={classes.thumbnail} draggable={false} />

            <div
              className={classes.grid}
              onMouseLeave={() => {
                setSelectionInProgress(false);
              }}
            >
              {tiles.map((cell, i) => (
                <div
                  key={i}
                  className={clsx(classes.cell, { [classes.active]: cell })}
                  draggable={false}
                  onMouseDown={() => {
                    setSelectionInProgress(true);
                    setTiles(adjust(i, () => !cell, tiles));
                  }}
                  onMouseEnter={() => {
                    if (isSelectionInProgress) {
                      setTiles(adjust(i, () => !cell, tiles));
                    }
                  }}
                  onMouseUp={() => {
                    setSelectionInProgress(false);
                  }}
                />
              ))}
            </div>
          </div>

          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="secondary"
              onClick={(event) => onClose?.(event, "escapeKeyDown")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.saveBtn}
              onClick={() => onSave?.(transformState(tiles))}
            >
              Save
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default AreaOfInterestDialog;
