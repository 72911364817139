import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Create styles
export const CameraInfoStyles = makeStyles({
  container: {
    padding: '7px 10px 12px 10px',
    backgroundColor: '#EFEFEF',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.85)',
    borderRadius: 13,

    position: 'absolute',
    top: '-100%',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    fontFamily: 'Barlow',
  },
  text_l: {
    fontSize: 16,
    fontWeight: 600,
  },
  text_m: {
    fontSize: 14,
  },
  text_s: {
    fontSize: 12,
  },
  textBold: {
    fontWeight: 700,
  },
  cameraName: {
    fontWeight: 600,
    marginRight: 5,
  },
  marker: {
    backgroundColor: '#FFE600',
    padding: '2px 4px',
    fontWeight: 600,

    marginTop: 9,
    marginBottom: 9,
  },
  cameraImg: {
    width: 128,
    height: 74,
    borderRadius: 3,
    objectFit: 'cover',
    marginRight: 8,
  },
});

export interface ICameraInfo {
  company: string;
  cameraName: string;
  address: string;
  scheduledService?: boolean;
  img?: string;
  uptime?: number;
  workingHours?: number;
  intrusions?: number;
  intrusionsDeterred?: number;
  verifiedAlarms?: number;
}

interface CameraInfoProps {
  info: ICameraInfo;
}

export const CameraInfo: FC<CameraInfoProps> = ({ info }) => {
  const classes = CameraInfoStyles({});
  return (
    <Box className={classes.container}>
      <span className={classes.text_l}>{info.company}</span>
      <Box mt='2px'>
        <span className={`${classes.text_m} ${classes.cameraName}`}>
          {info.cameraName}
        </span>
        <span className={classes.text_m}>{info.address}</span>
      </Box>
      {info.scheduledService && (
        <span className={`${classes.text_m} ${classes.marker}`}>
          This Niō needs Scheduled Service
        </span>
      )}
      <Box display='flex'>
        {info.img && (
          <img src={info.img} alt='camera' className={classes.cameraImg} />
        )}
        <Box display='flex' flexDirection='column'>
          <Typography noWrap className={classes.text_s}>
            <b>{`${info.uptime}%`}</b>{' '}
            {`uptime (${info.workingHours} h working)`}
          </Typography>
          <Typography noWrap className={classes.text_s}>
            <b>{info.intrusions}</b> intrusions
          </Typography>
          <Typography noWrap className={classes.text_s}>
            <b>{info.intrusionsDeterred}</b> intrusionsDeterred
          </Typography>
          <Typography noWrap className={classes.text_s}>
            <b>{info.verifiedAlarms}</b> video verified alarms
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
