import React from 'react';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import InfiniteScroll from 'react-infinite-scroller';
import {
  CircularProgress,
  createStyles,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import {
  RecordingInterface,
  RecordingsStoreInterface,
} from 'stores/recordingsStore';
import { FilterStoreInterface } from 'stores/filterStore';
import EventsList from './EventsList';
// import Grid from '@material-ui/core/Grid';
// import EvensBar from './EvensBar';
// import EventsSearchPanel from './EventsSearchPanel';

const styles = () =>
  createStyles({
    root: {
      padding: '24px 30px',
    },
  });

interface EventsProps extends WithStyles<typeof styles> {}

interface InjectedProps extends EventsProps {
  recordingsStore: RecordingsStoreInterface;
  filterStore: FilterStoreInterface;
}

@inject('recordingsStore', 'filterStore')
@observer
class Events extends React.Component<EventsProps, {}> {
  scrollParentRef: HTMLDivElement | null = null;

  state = {
    listHeight: 500,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  updateEventsSize = () => {
    this.setState({ listHeight: document.body.clientHeight - 250 });
  };

  componentDidMount() {
    const { recordingsStore, filterStore } = this.injected;
    // recordingsStore.loadAllRecords();
    recordingsStore.loadAllRecordsMock();
    window.addEventListener('resize', this.updateEventsSize);
    this.updateEventsSize();

    reaction(
      () => ({
        startDate: filterStore.startDate,
        endDate: filterStore.endDate,
        route: filterStore.route,
        company: filterStore.company,
        camera: filterStore.camera,
      }),
      () => recordingsStore.loadAllRecords(true),
      {
        delay: 300,
      }
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateEventsSize);
  }

  render() {
    const { recordingsStore } = this.injected;

    return (
      <>
        {!recordingsStore.loading && !recordingsStore.records.length && (
          <Typography color='textPrimary'>No events</Typography>
        )}
        {recordingsStore.loading && !recordingsStore.records.length ? (
          <CircularProgress />
        ) : (
          <InfiniteScroll
            loadMore={() => recordingsStore.loadAllRecords()}
            hasMore={
              recordingsStore.records.length ? !!recordingsStore.next : false
            }
            loader={<CircularProgress />}
            useWindow={false}
          >
            <EventsList
              list={recordingsStore.records as RecordingInterface[]}
            />
          </InfiniteScroll>
        )}
      </>
    );
  }
}

export default withStyles(styles)(Events);
