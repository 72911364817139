import { Instance, types } from "mobx-state-tree";

// resolution [0=1080p 1=4K]"
export const RecordingsSettings = types
  .model({
    resolution: types.optional(types.integer, 0),
    hdrEnabled: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    toggleVideoQuality() {
      self.resolution = self.resolution === 1 ? 0 : 1;
    },
    toggleHdr() {
      self.hdrEnabled = !self.hdrEnabled;
    },
  }));

export interface IRecordingsSettings
  extends Instance<typeof RecordingsSettings> {}
