import { Instance, types } from "mobx-state-tree";

export const MotionSettings = types
  .model({
    pir: types.optional(
      types.model({ sensitivity: types.optional(types.number, 1) }),
      {}
    ),
  })
  .actions((self) => ({
    setPirSensitivity(value: number) {
      self.pir.sensitivity = value;
    },
  }));

export interface IMotionSettings extends Instance<typeof MotionSettings> {}
