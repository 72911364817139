import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PieChart, Pie, Cell } from 'recharts';
import { Box, Typography, Grid, useMediaQuery } from '@material-ui/core';
import { colors } from '../../services/colors';

export const UpTimeChartStyles = makeStyles((theme) => ({
  workTime: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    marginTop: 10,
  },
  chartTextBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '5px',
    marginRight: '8px',
  },
  chartTextBlock2: {
    marginLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  chartText: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
  },
  greenText: {
    color: colors.green,
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  opacity: {
    opacity: 0.6,
  },
  redText: {
    color: colors.red,
    fontWeight: 600,
  },
  title: {
    fontFamily: 'Montserrat, Helvetica Neue, sans-serif',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '29px',
    color: '#F4F4F4',
  },
  marker: {
    fontSize: 14,
    fontWeight: 700,
    color: '#2E2E2E',

    padding: '2px 4px',
    marginBottom: 6,
    backgroundColor: colors.yellow,
  },
  subtitle: {
    marginBottom: 15,
    fontFamily: 'Montserrat, Helvetica Neue, sans-serif',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '22px',
    color: '#F4F4F4',
  },
}));

function UpTimeChart(props) {
  const classes = UpTimeChartStyles(props);
  // const { uptime = 0, downtime = 0, videoHours = 0 } = props;

  const mockData = {
    uptime: 86,
    downtime: 1,
    videoHours: 45,
  };

  const matches = useMediaQuery('(max-width: 1540px)');

  const offlineData = [
    {
      name: 'working',
      value: 86,
    },
    {
      name: 'down',
      value: 12,
    },
  ];

  return (
    <Box>
      <Typography className={classes.title}>98% Uptime</Typography>
      <div className={classes.workTime}>
        <Box className={classes.chartTextBlock}>
          <Box display='flex' flexDirection='row'>
            <span className={`${classes.chartText} ${classes.greenText}`}>
              {mockData.uptime} h
            </span>
            <span className={classes.chartText}>&nbsp;working</span>
          </Box>
          <Box display='flex' flexDirection='row' mt='8px'>
            <span
              className={`${classes.chartText} ${classes.greenText} ${classes.opacity}`}
            >
              {mockData.downtime} h
            </span>
            <span className={`${classes.chartText} ${classes.opacity}`}>
              &nbsp;down
            </span>
          </Box>
          {matches && (
            <Box display='flex' flexDirection='row' mt='8px'>
              <span className={`${classes.chartText} ${classes.redText}`}>
                {mockData.videoHours} h
              </span>
              <Box display='flex' flexDirection='column' alignItems='flex-end'>
                <span className={classes.chartText}>&nbsp;of videos</span>
                <span className={classes.chartText}>recorded</span>
              </Box>
            </Box>
          )}
        </Box>
        <PieChart width={117} height={117}>
          <defs>
            <pattern
              id='pattern'
              width='117'
              height='117'
              patternUnits='userSpaceOnUse'
            >
              <circle
                r='35'
                transform='translate(58.5,59)'
                stroke='#00D770'
                strokeWidth='19'
                strokeDasharray='2 2'
                fill='none'
              />
            </pattern>
            <pattern
              id='pattern1'
              width='117'
              height='117'
              patternUnits='userSpaceOnUse'
            >
              <circle
                r='55'
                transform='translate(58,60)'
                stroke='#FF0046'
                strokeWidth='8'
                strokeDasharray='3 2'
                fill='none'
              />
            </pattern>
          </defs>
          <Pie
            data={offlineData}
            dataKey='value'
            cx='50%'
            cy='50%'
            innerRadius={25}
            outerRadius={45}
            startAngle={180}
            endAngle={540}
            stroke={0}
          >
            {offlineData.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={'url(#pattern)'}
                opacity={index ? 0.4 : 1}
              />
            ))}
          </Pie>
          <Pie
            data={[{ name: 'video hours', value: 1 }]}
            dataKey='value'
            cx='50%'
            cy='50%'
            startAngle={90}
            endAngle={90 - 90}
            innerRadius={50}
            outerRadius={58}
            fill='url(#pattern1)'
            stroke={0}
          />
        </PieChart>
        {!matches && (
          <Box className={classes.chartTextBlock2}>
            <span className={`${classes.chartText} ${classes.redText}`}>
              {mockData.videoHours} h
            </span>
            <span className={classes.chartText}>of videos</span>
            <span className={classes.chartText}>recorded</span>
          </Box>
        )}
      </div>
      <Grid container>
        <Grid item xs={6}>
          <Typography className={classes.subtitle}>100% MTD</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.subtitle}>99.7% YTD</Typography>
        </Grid>
      </Grid>
      <Box display='flex' flexDirection='column'>
        <span className={classes.marker} style={{ width: 210 }}>
          20 Niōs need Scheduled Service
        </span>
        <span className={classes.marker} style={{ width: 81, marginLeft: 15 }}>
          1 Niō offline
        </span>
      </Box>
    </Box>
  );
}

export default UpTimeChart;
