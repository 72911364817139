import { ApisauceInstance, create } from "apisauce";
import qs from "qs";
import { AUTH_USER_TOKEN_KEY } from "./constants";
import { camelizeKeys, decamelizeKeys } from "humps";
import { IFile } from "stores/FilesStore/models/File";
import audiosMockData from "../mockResponses/audios.json";
import scriptsMockData from "../mockResponses/scripts.json";
import cameraStateMockData from "../mockResponses/cameraState.json";

export const apiUrl = process.env.REACT_APP_API_URL || window.location.origin;

export class Api {
  public client: ApisauceInstance;

  constructor() {
    this.client = create({
      baseURL: apiUrl,
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    this.client.addRequestTransform((request) => {
      const token = localStorage.getItem(AUTH_USER_TOKEN_KEY);

      if (token) request.headers["Authorization"] = `bearer:${token}`;

      // request.withCredentials = true;
      request.data = decamelizeKeys(request.data);
      request.params = decamelizeKeys(request.params);
    });
    this.client.addResponseTransform((response) => {
      response.data = camelizeKeys(response.data);
    });
  }

  getAudiosMock(companyId: string) {
    return Promise.resolve(camelizeKeys(audiosMockData));
  }

  getScriptsMock(companyId: string) {
    return Promise.resolve(camelizeKeys(scriptsMockData));
  }

  getCameraSettingsMock(companyId: string, cameraId: string) {
    return Promise.resolve(camelizeKeys(cameraStateMockData));
  }
}
