import {
  Instance,
  getParent,
  onSnapshot,
  getEnv,
  types,
  flow,
} from "mobx-state-tree";
import { AudioSettings } from "./AudioSettings";
import { LightsSettings } from "./LightsSettings";
import { RecordingsSettings } from "./RecordingsSettings";
import { ScriptSettings } from "./ScriptSettings";
import { AoiV2Settings } from "./AoiV2Settings";
import { MotionSettings } from "./MotionSettings";
import { Environment } from "../../Environment";

export const DesiredSettings = types
  .model("DesiredSettings", {
    motion: types.optional(MotionSettings, {}),
    script: types.optional(ScriptSettings, {}),
    location: types.optional(
      types.model({
        timezone: types.maybe(types.string),
        latitudeDd: types.maybe(types.number),
        longitudeDd: types.maybe(types.number),
      }),
      {}
    ),
    bundles: types.optional(
      types.model({
        audio: types.maybe(types.string),
        script: types.maybe(types.string),
      }),
      {}
    ),
    lights: types.optional(LightsSettings, {}),
    ai: types.optional(
      types.model({ aoiV2: types.optional(AoiV2Settings, {}) }),
      {}
    ),
    audio: types.optional(AudioSettings, {}),
    recordings: types.optional(RecordingsSettings, {}),
    revision: types.maybe(types.number),
  })
  .actions((self) => {
    const { api } = getEnv<Environment>(self);

    return {
      setCameraSettings: flow(function* (
        companyId: string,
        serialNumber: string,
        settings
      ) {
        yield api.client.patch(
          `/v2/companies/${companyId}/cameras/${serialNumber}/state`,
          settings
        );
      }),
    };
  });

export interface IDesiredSettings extends Instance<typeof DesiredSettings> {}
