import React, { ChangeEvent } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Box from "@material-ui/core/Box";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    switchLabel: {
      display: "flex",
      alignItems: "center",
    },
  })
);

export interface IMicrophoneProps {
  isMute?: boolean;
  onMuteChange?: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

export const Microphone = ({
  isMute,
  onMuteChange,
}: IMicrophoneProps) => {
  const classes = useStyles();

  return (
    <Box width="100%">
      <Grid component="label" container justify="space-between">
        <Grid className={classes.switchLabel} item>
          <Typography variant="body2">
          Mute Microphone
          </Typography>
        </Grid>
        <Grid item>
          <Switch checked={isMute} onChange={onMuteChange} />
        </Grid>
      </Grid>
    </Box>
  );
};
