import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function ToggleIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M16 20H2V4H16V20ZM18 8H22V4H18V8ZM18 20H22V16H18V20ZM18 14H22V10H18V14Z"/>
        </SvgIcon>
    );
}

export default ToggleIcon;