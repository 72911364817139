import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

import {
  Box,
  CircularProgress,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { usePDF, Document } from '@react-pdf/renderer';
import { useSnackbar } from 'notistack';

import { WorkTimeReport } from './WorkTimeReport';
import { CameraCard } from './CameraCard';
import { ActivityChart } from './ActivityChart';
import { EventsFrequency } from './EventsFrequency';

import { useStores } from '../../stores/RootStore';
import { LogoIcon } from 'components/icons/Logo';
import { colors } from '../../services/colors';

import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import PrintIcon from '@material-ui/icons/Print';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ReorderIcon from '@material-ui/icons/Reorder';
import ViewListIcon from '@material-ui/icons/ViewList';

import { PDFReport } from 'components/PDFReport/PDFReport';

// Create styles
export const ReportStyles = makeStyles<Theme, {}>((theme) => ({
  background: {
    backgroundColor: colors.gray_light,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingTop: 64,
    paddingBottom: 130,
  },
  page: {
    width: '100%',
    maxWidth: 960,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 30px rgba(17, 17, 17, 0.1)',
    padding: '85px 90px 60px 90px',
    fontFamily: 'Barlow',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  headerTitle: {
    fontWeight: 500,
    fontSize: 32,
    marginBottom: 12,
  },
  headerSubtitle: {
    fontWeight: 500,
    fontSize: 16,
  },
  subHeader: {
    fontSize: 32,
    fontWeight: 500,
    marginTop: 23,
  },
  line: {
    position: 'absolute',
    left: '-90px',
    width: 'calc(((100vw - (100% + 180px))/2) + 100% + 170px)',
    // (document width - width page + paddings) / 2 = distance from page to edge of document
    borderTop: '1px dashed #999999',
  },
  activity: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 55,
    marginBottom: 70,
  },
  activityTitle: {
    fontWeight: 500,
    fontSize: 32,
    marginBottom: 19,
  },
  activityStats: {
    display: 'flex',
    alignItems: 'center',
    '&:first-child': {
      marginRight: 82,
    },
  },
  activityText: {
    fontSize: 24,
    fontWeight: 500,
  },
  marker: {
    color: '#fff',
    backgroundColor: '#000000',

    borderRadius: 2,
    marginRight: 9,
    padding: '0 8px',

    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 22,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    position: 'absolute',
    left: 'calc(50% + 504px)',

    [theme.breakpoints.down('md')]: {
      left: '50%',
      transform: 'translateX(-50%)',
      width: 960,
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
    },
  },
  topButtons: {
    '&>*': {
      marginBottom: 20,
    },
    [theme.breakpoints.down('md')]: {
      top: '1%',
      paddingLeft: '24px',
    },
  },
  middleButtons: {
    [theme.breakpoints.down('md')]: {
      padding: '0 90px',
      marginTop: '-35px',
    },
  },
  closeIcon: {
    width: 48,
    height: 48,
    borderRadius: 3,
  },
}));

const Report = observer(() => {
  const classes = ReportStyles({});
  const { reportStore, authStore, filterStore } = useStores();

  const { enqueueSnackbar } = useSnackbar();

  const { getReportMock } = reportStore;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const period = searchParams.get('period');

  const [periodStr, setPeriodStr] = useState(format(new Date(), 'MM/dd/yyyy'));
  const [reportStatus, setReportStatus] = useState('previews');

  const [instance, updateInstance] = usePDF({
    document: reportStore.isLoaded ? (
      <PDFReport
        activities={reportStore.activities}
        activityCharts={reportStore.activityCharts}
        period={periodStr}
        workReport={reportStore.allReports}
        reportStatus={reportStatus}
      />
    ) : (
      <Document />
    ),
  });

  useEffect(() => {
    getReportMock(authStore.poolInfo?.identityPoolId, period);
  }, [
    getReportMock,
    period,
    authStore,
    filterStore.startDateString,
    filterStore.endDateString,
  ]);

  useEffect(() => {
    if (period) {
      const [startDateTimestamp, endDateTimestamp] = period.split(':');
      const startDate = format(new Date(+startDateTimestamp), 'MM/dd/yyyy');
      const endDate = format(new Date(+endDateTimestamp), 'MM/dd/yyyy');
      setPeriodStr(
        startDate === endDate ? startDate : `${startDate} to ${endDate}`
      );
    }
  }, [period]);

  useEffect(() => {
    if (reportStore.isLoaded) {
      updateInstance();
    } else {
      enqueueSnackbar('Pdf file is generated', { variant: 'info' });
    }
  }, [reportStore.isLoaded, enqueueSnackbar]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reportStore.isLoaded && instance.url) {
      enqueueSnackbar('Done!', { variant: 'success' });
    }
  }, [instance]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reportStore.isLoaded) {
      enqueueSnackbar('Pdf file is rerender', { variant: 'info' });
      updateInstance();
    }
  }, [reportStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const previewsStatus = () => {
    setReportStatus('previews');
  };

  const textOnlyStatus = () => {
    setReportStatus('text');
  };

  const hideStatus = () => {
    setReportStatus('hide');
  };

  const printPdf = (url) => {
    const wnd = window.open(url);
    wnd?.print();
  };

  return (
    <>
      <Box className={classes.background}>
        <Box className={classes.page}>
          <Box className={classes.header}>
            <LogoIcon />
            <Box className={classes.headerText}>
              <Typography variant='h4' className={classes.headerTitle}>
                Starlight Security Month Report
              </Typography>
              <span className={classes.headerSubtitle}>{periodStr}</span>
              <span className={classes.headerSubtitle}>
                starlightsecurity.idter.com
              </span>
            </Box>
          </Box>

          <WorkTimeReport {...reportStore.allReports} />

          <Box className={classes.activity}>
            <Typography variant='h4' className={classes.activityTitle}>
              Activity Summary
            </Typography>
            <Box display='flex' alignItems='center'>
              <Box className={classes.activityStats}>
                <span className={`${classes.activityText} ${classes.marker}`}>
                  237
                </span>
                <span className={classes.activityText}>
                  events triggered overall
                </span>
              </Box>
              <Box className={classes.activityStats}>
                <span className={`${classes.activityText} ${classes.marker}`}>
                  204
                </span>
                <span className={classes.activityText}>
                  video verified alarms
                </span>
              </Box>
            </Box>

            <ActivityChart />

            <Box
              display='flex'
              alignItems='center'
              width='100%'
              justifyContent='space-between'
            >
              <EventsFrequency
                title='Most Events'
                time='11 pm to 2 am (night)'
                cameraName='Back Entrance'
                address='1254 Cherry Lane'
              />
              <EventsFrequency
                title='Least Events (greater than 0)'
                time='1 pm to 2 pm (midday)'
                cameraName='Side Entrance 1'
                address='3601 Old Airport Rd.'
              />
            </Box>
          </Box>

          <Box
            className={`${classes.buttons} ${classes.middleButtons}`}
            mt='23px'
          >
            <Button
              color='primary'
              startIcon={<ViewListIcon />}
              onClick={previewsStatus}
            >
              Previews
            </Button>
            <Button
              color='primary'
              startIcon={<ReorderIcon />}
              onClick={textOnlyStatus}
            >
              Text Only
            </Button>
            <Button
              color='primary'
              startIcon={<VisibilityOffIcon />}
              onClick={hideStatus}
            >
              Don’t Show
            </Button>
          </Box>

          <Box style={{ position: 'relative', width: '100%', height: 3 }}>
            <hr className={classes.line} />
          </Box>

          {reportStatus !== 'hide' && (
            <>
              <Typography variant='h5' className={classes.subHeader}>
                Niō Nodes Arranged by Events number
              </Typography>

              {reportStore.loading ? (
                <CircularProgress />
              ) : (
                reportStore.activities.map((activity, id) => (
                  <CameraCard
                    key={id}
                    thumbnailBase64={activity.thumbnailBase64}
                    workingHours={activity.workingHours}
                    events={activity.events}
                    cameraName={activity.cameraName}
                    companyName={activity.companyName}
                    uptime={activity.uptime}
                    imgIsVisible={reportStatus === 'previews'}
                  />
                ))
              )}
            </>
          )}
        </Box>
        <Box className={`${classes.buttons} ${classes.topButtons}`}>
          <NavLink to={{ pathname: '/dashboard' }}>
            <IconButton
              size='small'
              aria-label='close'
              className={classes.closeIcon}
            >
              <CloseIcon fontSize='large' htmlColor={colors.mainBlue} />
            </IconButton>
          </NavLink>

          {reportStore.isLoaded && instance.url && !instance.loading ? (
            <>
              <a
                href={instance.url}
                download={`report_${period}.pdf`}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<GetAppIcon />}
                >
                  Download PDF
                </Button>
              </a>
              <Button
                variant='contained'
                color='primary'
                startIcon={<PrintIcon />}
                onClick={() => printPdf(instance.url)}
              >
                Print
              </Button>
            </>
          ) : (
            <>
              <Button
                variant='contained'
                color='primary'
                startIcon={<GetAppIcon />}
                disabled={true}
              >
                Download PDF
              </Button>
              <Button
                variant='contained'
                color='primary'
                startIcon={<PrintIcon />}
                disabled={true}
              >
                Print
              </Button>
            </>
          )}
        </Box>
      </Box>
    </>
  );
});

export default Report;
