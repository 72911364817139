import { flow, Instance, types } from 'mobx-state-tree';
import { withEnvironment } from './withEnvironment';
import { format } from 'date-fns';
import { withStores } from './withStores';
import config from '../config';

export const ActivityReport = types
  .model({
    cameraName: types.string,
    address: types.optional(types.maybeNull(types.string), ''),
    lat: types.optional(types.string, ''),
    lng: types.optional(types.string, ''),
    timezone: types.optional(types.string, ''),
    companyName: types.optional(types.string, ''),
    workingHours: types.number,
    downtimeHours: types.number,
    events: types.number,
    dispatch: types.optional(types.maybeNull(types.string), ''),
    thumbnailBase64: types.optional(types.maybeNull(types.string), ''),
  })
  .views((self) => ({
    get uptime() {
      const uptime = self.workingHours
        ? 100 - self.downtimeHours / self.workingHours
        : 0;

      return Math.round(uptime * 100) / 100;
    },
  }));

export interface ActivityReportInterface
  extends Instance<typeof ActivityReport> {}

export const EventsByHour = types
  .model({
    recordings: types.number,
    missedDetection: types.maybeNull(types.number),
    engagements: types.number,
    hour: types.number,
  })
  .views((self) => ({
    get dayString() {
      return format(new Date(self.hour), 'd MMM');
    },
  }));

export const StatsByDaterange = types.model({
  intrusions: types.number,
  deterredIntrusions: types.number,
  alarms: types.number,
});

export interface EventsByHourInterface extends Instance<typeof EventsByHour> {}

export const Stats = types
  .model({
    events: types.array(EventsByHour),
    eventsTriggered: types.number,
    missedDetection: types.number,
    engagements: types.number,
    workingHours: types.number,
    downtimeHours: types.number,
    intrusions: types.number,
    deterredIntrusions: types.number,
    alarms: types.number,
    mtd: types.optional(types.maybeNull(StatsByDaterange), null),
    ytd: types.optional(types.maybeNull(StatsByDaterange), null),
  })
  .views((self) => ({
    get eventsByDay() {
      const eventsByDay = {};

      self.events.forEach((event) => {
        if (event.dayString in eventsByDay) {
          eventsByDay[event.dayString].recordings += event.recordings;
          eventsByDay[event.dayString].engagements += event.engagements;
          if (
            eventsByDay[event.dayString].missedDetection === null &&
            event.missedDetection === null
          ) {
            eventsByDay[event.dayString].missedDetection = null;
          } else {
            eventsByDay[event.dayString].missedDetection +=
              event.missedDetection;
          }
        } else {
          eventsByDay[event.dayString] = {
            recordings: event.recordings,
            engagements: event.engagements,
            missedDetection: event.missedDetection,
            dayString: event.dayString,
            hour: event.hour,
          };
        }
      });

      return Object.values(eventsByDay);
    },
  }));

export interface StatsInterface extends Instance<typeof Stats> {}

export const ActivityCharts = types
  .model({
    reportPieChart: types.optional(types.maybeNull(types.string), ''),
    activityChart: types.optional(types.maybeNull(types.string), ''),
  })
  .actions((self) => ({
    setActivityChart: function (url) {
      self.activityChart = url;
    },
    setReportPieChart: function (url) {
      self.reportPieChart = url;
    },
  }));

export interface ActivityChartsInterface
  extends Instance<typeof ActivityCharts> {}

export const ReportStore = types
  .model({
    activities: types.optional(types.array(ActivityReport), []),
    stats: types.optional(types.maybeNull(Stats), null),
    activityCharts: types.optional(ActivityCharts, {}),
    loading: false,
  })
  .extend(withStores)
  .extend(withEnvironment)
  .views((self) => ({
    get allReports() {
      const allReports = {
        working: 0,
        down: 0,
        hoursVideo: 0,
        uptime: 0,
      };

      self.activities.forEach((active) => {
        allReports.working += active.workingHours;
        allReports.down += active.downtimeHours;
      });

      for (const key in allReports) {
        allReports[key] = Math.round(allReports[key]);
      }

      allReports.uptime = Math.round(
        ((allReports.working - allReports.down) * 100) / allReports.working
      );

      if (isNaN(allReports.uptime)) {
        allReports.uptime = 0;
      }

      return allReports;
    },
    get isLoaded() {
      let isLoaded = false;

      const { activities, activityCharts } = self;

      if (
        activities &&
        activityCharts.activityChart &&
        activityCharts.reportPieChart
      ) {
        isLoaded = true;
      }

      return isLoaded;
    },
  }))
  .actions((self) => ({
    getReport: flow(function* (companyId?: string, period = false) {
      self.loading = true;

      const response = yield self.environment.api.client.get(
        '/v2/companies/' + companyId + '/reports/activity',
        {
          period:
            period ||
            `${self.filterStore.startDateTimestamp}:${self.filterStore.endDateTimestamp}`,
          companyId: self.filterStore.company
            ? self.filterStore.company.companyId
            : undefined,
        }
      );

      if (response.ok) {
        self.activities = response?.data?.activities;
      } else {
        self.activities.clear();
      }

      self.loading = false;
    }),
    getReportMock: flow(function* (companyId?: string, period = false) {
      self.loading = true;

      const response = yield self.environment.api.client.get(
        `${config.ROOT_PATH}json/reports.json`,
        {
          period:
            period ||
            `${self.filterStore.startDateTimestamp}:${self.filterStore.endDateTimestamp}`,
          companyId: self.filterStore.company
            ? self.filterStore.company.companyId
            : undefined,
        }
      );

      if (response.ok) {
        self.activities = response?.data?.activities;
      } else {
        self.activities.clear();
      }

      self.loading = false;
    }),
    loadStats: flow(function* (companyId?: string) {
      self.loading = true;

      const route = self.filterStore.camera
        ? `/v2/companies/${companyId}/reports/camera/${self.filterStore.camera.serialNumber}/stats`
        : self.filterStore.route
        ? `/v2/companies/${companyId}/reports/route/${self.filterStore.route.routeId}/stats`
        : `/v2/companies/${companyId}/reports/stats`;

      const response = yield self.environment.api.client.get(route, {
        period: `${self.filterStore.startDateTimestamp}:${self.filterStore.endDateTimestamp}`,
        companyId: self.filterStore.company
          ? self.filterStore.company.companyId
          : undefined,
      });

      if (response.ok) {
        self.stats = response?.data;
      }

      self.loading = false;
    }),
    loadStatsMock: flow(function* (companyId?: string) {
      self.loading = true;

      const response = yield self.environment.api.client.get(
        `${config.ROOT_PATH}json/events.json`,
        {
          period: `${self.filterStore.startDateTimestamp}:${self.filterStore.endDateTimestamp}`,
          companyId: self.filterStore.company
            ? self.filterStore.company.companyId
            : undefined,
        }
      );
      if (response.ok) {
        self.stats = response?.data;
      }

      self.loading = false;
    }),
  }));

export interface ReportsStoreInterface extends Instance<typeof ReportStore> {}
