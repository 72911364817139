import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { darkTheme } from 'services/theme';
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  Box,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { Panel } from 'components/Panel';
import { CamerasListContainer } from 'components/CamerasList';

const styles = (theme: Theme) =>
  createStyles({
    content: {
      padding: '24px 48px',
    },
  });

interface DashboardProps
  extends WithStyles<typeof styles>,
    RouteComponentProps {}

class Dashboard extends React.Component<DashboardProps, {}> {
  render() {
    const { classes } = this.props;

    return (
      <ThemeProvider theme={darkTheme}>
        <Panel>
          <Box className={classes.content}>
            <CamerasListContainer />
          </Box>
        </Panel>
      </ThemeProvider>
    );
  }
}

export default withRouter(withStyles(styles)(Dashboard));
