import { createMuiTheme, Theme } from "@material-ui/core/styles";
import { colors } from "./colors";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.mainBlue,
    },
    tonalOffset: 0.2,
  },
  shadows: Array<string>(25).fill("none") as Theme["shadows"],
  typography: {
    fontSize: 16,
    fontFamily: "Barlow",
    button: {
      textTransform: "none",
    },
  },
});

export const darkTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.mainBlue,
    },
    action: {
      disabledBackground: colors.mainBlue,
    },
    background: {
      default: "#222222",
      paper: "#000",
    },
    text: {
      primary: "#F4F4F4",
      secondary: "#fff",
    },
    tonalOffset: 0.2,
  },
  shadows: Array<string>(25).fill("none") as Theme["shadows"],
  typography: {
    fontSize: 16,
    fontFamily: "Barlow",
    button: {
      textTransform: "none",
    },
  },
});
