import Grid from '@material-ui/core/Grid';
// import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import Typography from '@material-ui/core/Typography';
import React, { lazy, Suspense } from 'react';
import Button from '@material-ui/core/Button';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AuthStoreInterface } from '../../stores/authStore';
import { RecordingsStoreInterface } from '../../stores/recordingsStore';

const ProfileModal = lazy(() => import('components/ProfileModal'));

interface PanelProfileProps extends RouteComponentProps {
}

interface InjectedProps extends PanelProfileProps {
  authStore: AuthStoreInterface;
  recordingsStore: RecordingsStoreInterface;
}

interface State {
  profileOpen: boolean | null;
}

@inject('authStore', 'recordingsStore')
@observer
class PanelProfile extends React.Component<PanelProfileProps, State> {

  state = {
    profileOpen: null,
  };

  handleSignOutClick = () => {
    const { authStore, history } = this.props as InjectedProps;
    authStore.logout().then(() => {
      history.push('/login');
    });
  };

  handleProfileClose = () => {
    this.setState({ profileOpen: null });
  };

  handleProfileShow = () => {
    this.setState({ profileOpen: true });
  };

  render() {
    const { profileOpen } = this.state;

    return (
      <Grid container alignItems='center' justify='space-between'>
        <Grid item>
          <Button onClick={this.handleProfileShow}>Profile</Button>
          <Suspense fallback={<></>}>
            {profileOpen && <ProfileModal closeModal={this.handleProfileClose} />}
          </Suspense>
        </Grid>
        <Grid item>
          <Button onClick={this.handleSignOutClick}>Sign&nbsp;out</Button>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(PanelProfile);

