export const SortTypeToText = {
  'date': 'Date',
  'activity': 'Activity',
  'name': 'Name',
}

export enum RecordDescription {
  Person = 'person',
  Vehicle = 'vehicle',
  Alert = 'alert',
  Manual = 'manual',
  PersonVehicle = 'person,vehicle',
  Car = 'car',
  Rec = 'record',
}
