const RED = "#FF0046";
const YELLOW = "#FFC700";
const BLACK = "#000000";

export const data = [
  {
    lat: 37.80034479962077,
    lng: -122.39896644337584,
    color: RED,
    rotate: "135deg",
  },
  {
    lat: 37.79925437877127,
    lng: -122.39804248265702,
    color: YELLOW,
    rotate: "-30deg",
  },
  {
    lat: 37.79922066353389,
    lng: -122.39870395974495,
    color: BLACK,
  },
  {
    lat: 37.801665,
    lng: -122.400441,
    color: RED,
    rotate: "135deg",
  },
  {
    lat: 37.800728,
    lng: -122.399345,
    color: RED,
    rotate: "-45deg",
  },
  {
    lat: 37.800720,
    lng: -122.400406,
    color: YELLOW,
    rotate: "70deg",
  },
  {
    lat: 37.797803,
    lng: -122.395413,
    color: BLACK,
    rotate: "45deg",
  },
  {
    lat: 37.798837,
    lng: -122.393765,
    color: YELLOW,
    rotate: "-135deg",
  },
  {
    lat: 37.798847,
    lng: -122.399975,
    color: YELLOW,
    rotate: "35deg",
  },
];
