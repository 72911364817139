import React, { useState, useEffect } from "react";
// Material UI imports
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Switch from "@material-ui/core/Switch";
import WbSunny from "@material-ui/icons/WbSunny";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    switchLabel: {
      display: "flex",
      alignItems: "center",
    },
    divider: {
      margin: theme.spacing(1, 0),
    },
  })
);

const useBrightnessStyles = makeStyles(() =>
  createStyles({
    thumb: {
      height: 20,
      width: 20,
      marginTop: -9,
      marginLeft: -11,
    },
  })
);

const useColorTemperatureStyles = makeStyles(() =>
  createStyles({
    rail: {
      height: 3,
      border: "solid 1px #c7c7c7",
      borderRadius: 4,
      background:
        "linear-gradient(90deg, rgb(255,255,50) 0%, rgb(255,255,255) 50%, rgb(50,255,255) 100%)",
    },
    thumb: {
      height: 20,
      width: 20,
      marginTop: -8,
      marginLeft: -10,
    },
  })
);

export interface ILightsProps {
  topLights?: boolean;
  bottomLights?: boolean;
  brightness?: number;
  colorTemperature?: number;
  onTopLightsChange?: (checked: boolean) => void;
  onBottomLightsChange?: (checked: boolean) => void;
  onBrightnessChange?: (value: number) => void;
  onColorTemperatureChange?: (value: number) => void;
}

export const Lights = ({
  topLights,
  bottomLights,
  brightness = 0.5,
  colorTemperature = 0.5,
  onTopLightsChange,
  onBottomLightsChange,
  onBrightnessChange,
  onColorTemperatureChange,
}: ILightsProps) => {
  const classes = useStyles();
  const [brightnessSliderValue, setBrightnessSliderValue] = useState<number>(
    brightness
  );
  const [colorTempSliderValue, setColorTempSliderValue] = useState<number>(
    colorTemperature
  );

  useEffect(() => {
    setBrightnessSliderValue(brightness);
  }, [brightness]);

  useEffect(() => {
    setColorTempSliderValue(colorTemperature);
  }, [colorTemperature]);

  return (
    <Box width="100%">
      <Grid component="label" container justify="space-between">
        <Grid className={classes.switchLabel} item>
          <Typography variant="body2">Top Lights</Typography>
        </Grid>
        <Grid item>
          <Switch
            checked={topLights}
            onChange={(_, checked) => onTopLightsChange?.(checked)}
          />
        </Grid>
      </Grid>

      <Grid component="label" container justify="space-between">
        <Grid className={classes.switchLabel} item>
          <Typography variant="body2">Bottom Lights</Typography>
        </Grid>
        <Grid item>
          <Switch
            checked={bottomLights}
            onChange={(_, checked) => onBottomLightsChange?.(checked)}
          />
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <Typography variant="body2" gutterBottom>
        Brightness
      </Typography>
      <Grid container spacing={2}>
        <Grid item>
          <WbSunny />
        </Grid>
        <Grid item xs>
          <Slider
            value={brightnessSliderValue}
            classes={useBrightnessStyles()}
            onChange={(_, newValue) =>
              setBrightnessSliderValue(newValue as number)
            }
            onChangeCommitted={(_, value) =>
              onBrightnessChange?.(value as number)
            }
            min={0}
            max={1}
            step={0.01}
          />
        </Grid>
        <Grid item>
          <WbSunny />
        </Grid>
      </Grid>

      <Typography variant="body2" gutterBottom>
        Color Temperature
      </Typography>
      <Slider
        value={colorTempSliderValue}
        classes={useColorTemperatureStyles()}
        onChange={(_, newValue) => setColorTempSliderValue(newValue as number)}
        onChangeCommitted={(_, value) =>
          onColorTemperatureChange?.(value as number)
        }
        min={0}
        max={1}
        step={0.01}
        track={false}
      />
    </Box>
  );
};
