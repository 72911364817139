import PersonIcon from '@material-ui/icons/DirectionsWalk';
import CarIcon from '@material-ui/icons/DirectionsCar';
import RecordIcon from '@material-ui/icons/FiberManualRecord';
import {RecordDescription} from '../components/Events/consts';

export function calcDuration(sec: number) {
  let seconds = sec;

  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  return `${minutes}m ${seconds}s`;
}

export function getInfoIcon(type: RecordDescription) {
  switch (type) {
    case RecordDescription.Person:
      return PersonIcon;
    case RecordDescription.Vehicle:
    case RecordDescription.Car:
      return CarIcon;
    case RecordDescription.Rec:
      return RecordIcon;
    default:
      return null;
  }
}

export function getInfoText(type: RecordDescription) {
  switch (type) {
    case RecordDescription.Person:
      return 'Person';
    case RecordDescription.Vehicle:
    case RecordDescription.Car:
      return 'Vehicle';
    case RecordDescription.Rec:
      return 'Recording';
    case RecordDescription.PersonVehicle:
      return 'Person, Vehicle';
    case RecordDescription.Manual:
      return 'Manual';
    default:
      return '';
  }
}

// @ts-ignore
export const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

// @ts-ignore
export const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

export const isSupportsH265 = isSafari || isIE11;