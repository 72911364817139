import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocationOnIcon from '@material-ui/icons/LocationOn';

// Create styles
export const EventsFrequencyStyles = makeStyles({
  eventsFrequency: {
    display: 'flex',
    flexDirection: 'column',
  },
  eventsFrequencyTitle: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 5,
  },
  eventsFrequencyText: {
    fontSize: 18,
    fontWeight: 500,
    marginLeft: 5,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface EventsFrequencyProps {
  title: string;
  time: string;
  cameraName: string;
  address: string;
}

export const EventsFrequency: FC<EventsFrequencyProps> = ({
  title,
  time,
  cameraName,
  address,
}) => {
  const classes = EventsFrequencyStyles({});
  return (
    <Box className={classes.eventsFrequency}>
      <Typography variant='h6' className={classes.eventsFrequencyTitle}>
        {title}
      </Typography>
      <Box className={classes.flex} mb={'10px'}>
        <AccessTimeIcon fontSize='small' />
        <span className={classes.eventsFrequencyText}>{time}</span>
      </Box>
      <Box className={classes.flex}>
        <LocationOnIcon fontSize='small' />
        <span className={classes.eventsFrequencyText}>
          <b>{cameraName}</b> {address}
        </span>
      </Box>
    </Box>
  );
};
