import { Instance, types, getSnapshot } from "mobx-state-tree";
import { createContext, useContext } from "react";
import { AuthStore } from "./authStore";
import { RecordingsStore } from "./recordingsStore";
import { ReportStore } from "./reportStore";
import { FilterStore } from "./filterStore";
import { SidebarStore } from "./sidebarStore";
import { CameraSettingsStore } from "./СameraSettingsStore";
import { FilesStore } from "./FilesStore";

declare global {
  interface Window {
    getRootStore: any;
  }
}

export const RootStore = types
  .model("RootStore", {
    appLoaded: types.optional(types.boolean, false),
    authStore: types.late(() => types.optional(AuthStore, {})),
    recordingsStore: types.late(() => types.optional(RecordingsStore, {})),
    reportStore: types.late(() => types.optional(ReportStore, {})),
    filterStore: types.late(() => types.optional(FilterStore, {})),
    sidebarStore: types.late(() => types.optional(SidebarStore, {})),
    cameraSettingsStore: types.optional(CameraSettingsStore, {}),
    filesStore: types.optional(FilesStore, {}),
  })
  .actions((self) => ({
    setAppLoaded() {
      self.appLoaded = true;
    },
  }));

export interface RootStoreInterface extends Instance<typeof RootStore> {}

export const RootStoreContext = createContext<null | RootStoreInterface>(null);

export const MstProvider = RootStoreContext.Provider;

export function useStores(): RootStoreInterface {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
}
