import React, { FC } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { styles } from './PDFReport';

const PDFCameraCardStyles = StyleSheet.create({
  image: {
    width: 132,
    height: 75,
    borderRadius: 8,
    objectFit: 'cover',
    marginRight: 12,
  },
  plug: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'black',
  },
  text: {
    fontSize: 9,
    fontWeight: 400,
  },
  textBold: {
    fontSize: 9,
    fontWeight: 700,
    marginRight: 3,
  },
  info: {
    display: 'flex',
    height: '100%',
    alignItems: 'flex-start',
  },
  infoText: {
    marginTop: 11,
  },
});

interface PDFCameraCardProps {
  thumbnailBase64: string | null;
  workingHours?: number;
  events?: number;
  cameraName?: string;
  companyName?: string;
  uptime?: number;
  imgIsVisible?: boolean;
}

export const PDFCameraCard: FC<PDFCameraCardProps> = ({
  thumbnailBase64,
  workingHours,
  events,
  cameraName,
  companyName,
  uptime,
  imgIsVisible,
}) => {
  return (
    <View style={[styles.flexRowCenter, { marginTop: 20 }]} wrap={false}>
      {imgIsVisible && (
        <>
          {thumbnailBase64 ? (
            <Image src={thumbnailBase64} style={PDFCameraCardStyles.image} />
          ) : (
            <View
              style={[PDFCameraCardStyles.image, PDFCameraCardStyles.plug]}
            />
          )}
        </>
      )}

      <View style={PDFCameraCardStyles.info}>
        <View style={styles.flexRowCenter}>
          <Text style={[styles.text_m, { fontWeight: 700, marginRight: 7 }]}>
            {cameraName}
          </Text>
          <Text style={styles.text_m}>{companyName}</Text>
        </View>
        <View style={PDFCameraCardStyles.infoText}>
          <View style={styles.flexRowCenter}>
            <Text style={PDFCameraCardStyles.textBold}>{`${uptime}%`}</Text>
            <Text
              style={PDFCameraCardStyles.text}
            >{`uptime (${workingHours} h working)`}</Text>
          </View>
          <View style={styles.flexRowCenter}>
            <Text style={PDFCameraCardStyles.textBold}>{events}</Text>
            <Text style={PDFCameraCardStyles.text}>events</Text>
          </View>
          <View style={styles.flexRowCenter}>
            <Text style={PDFCameraCardStyles.textBold}>{0}</Text>
            <Text style={PDFCameraCardStyles.text}>video verified alarms</Text>
          </View>
        </View>
      </View>
    </View>
  );
};
