import {Instance, types} from 'mobx-state-tree';
import {withEnvironment} from './withEnvironment';

export const SidebarStore = types
  .model({
    isOpen: false
  })
  .extend(withEnvironment)
  .views(self => ({}))
  .actions((self) => ({}))
  .actions(self => ({
    toggleView() {
      self.isOpen = !self.isOpen;
    }
  }));

export interface SidebarStoreInterface extends Instance<typeof SidebarStore> {
}
