import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Button, useTheme, useMediaQuery } from '@material-ui/core';
import { LogoIcon } from 'components/icons/Logo';

import DashboardProfile from './PanelProfile';

export const PanelHeaderTopStyles = makeStyles((theme) => ({
  root: {
    padding: '34px 48px 22px',
    [theme.breakpoints.down('md')]: {
      padding: '12px 32px 6px 32px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '12px 24px',
    },
  },
  rootBg: {
    background: '#1A1A1A',
  },
  navLink: {
    fontWeight: 'normal',
    '&:not(:first-child)': {
      marginLeft: 94,
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
      },
    },
    '&.active': {
      color: '#FF0046',
    },
  },
  navLinkLabel: {
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '29px',
    letterSpacing: '-0.456px',
  },
}));

function PanelHeaderTop(props) {
  const classes = PanelHeaderTopStyles(props);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      container
      className={clsx(classes.root, {
        [classes.rootBg]: props.withBg,
      })}
    >
      <Grid container spacing={matches ? 0 : 3}>
        <Grid item xs={matches ? 8 : 9}>
          <Grid container alignItems='center'>
            <Grid item xs={12} sm={matches ? 2 : 3}>
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                p={matches ? 1 : 0}
              >
                <LogoIcon color='white' />
              </Box>
            </Grid>

            <Grid item xs={12} sm={matches ? 10 : 9}>
              <Grid
                container
                justify={matches ? 'space-between' : 'flex-start'}
              >
                <Button
                  classes={{
                    root: classes.navLink,
                    label: classes.navLinkLabel,
                  }}
                  to={{ pathname: '/map' }}
                  component={NavLink}
                  exact
                >
                  Niō Map
                </Button>
                <Button
                  classes={{
                    root: classes.navLink,
                    label: classes.navLinkLabel,
                  }}
                  to={{ pathname: '/cameras' }}
                  component={NavLink}
                  exact
                >
                  Niō List
                </Button>
                <Button
                  classes={{
                    root: classes.navLink,
                    label: classes.navLinkLabel,
                  }}
                  to={{ pathname: '/dashboard' }}
                  component={NavLink}
                  exact
                >
                  Niō Events & Recordings
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={matches ? 4 : 3}>
          <Box px={3} height='100%' display='flex' alignItems='center'>
            <DashboardProfile />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PanelHeaderTop;
