import { Instance, types } from "mobx-state-tree";

// night-vision mode [0=disabled, 1=auto-detect, 2=scheduled]
// 0 and 1 are used only at web UI

const AlertSoundFiles = types.model({
  alarm: types.optional(types.string, ""),
  alarmChirp: types.optional(types.string, ""),
  approaching: types.optional(types.string, ""),
  proximity: types.optional(types.string, ""),
  warning1: types.optional(types.string, ""),
  warning2: types.optional(types.string, ""),
  warning3: types.optional(types.string, ""),
});

export const ScriptSettings = types
  .model({
    nightVision: types.optional(types.integer, 0),
    alertSoundFiles: types.optional(AlertSoundFiles, {}),
    profileFile: types.maybeNull(types.string),
    allCameraLightsOn: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    togleNightVision() {
      self.nightVision = self.nightVision === 1 ? 0 : 1;
    },

    setFirstVoiceDown(value: string) {
      self.alertSoundFiles.warning2 = value;
    },

    setAlertedVoiceDown(value: string) {
      self.alertSoundFiles.warning1 = value;
    },

    setProfileFile(value: string) {
      self.profileFile = value;
    },

    toggleNioMesh() {
      self.allCameraLightsOn = !self.allCameraLightsOn;
    },
  }));

export interface IScriptSettings extends Instance<typeof ScriptSettings> {}
