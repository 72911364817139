import React from 'react';
import {
  Text,
  View,
  StyleSheet,
  Image,
  // Font,
} from '@react-pdf/renderer';
import { styles } from './PDFReport';

import ClockIco from './img/clock.png';
import LocationIco from './img/location.png';

const eventsFrequencyStyles = StyleSheet.create({
  text: {
    fontSize: 9,
    fontWeight: 500,
    marginLeft: 3,
  },
  textBold: {
    fontSize: 9,
    fontWeight: 700,
    marginLeft: 3,
  },
});

export const PDFEventsFrequency = ({ title, time, cameraName, address }) => {
  return (
    <View style={{ marginRight: 45 }}>
      <Text style={[styles.text_m, { marginBottom: 3 }]}>{title}</Text>
      <View style={[styles.flexRowCenter, { marginBottom: 4 }]}>
        <Image src={ClockIco} style={{ width: 12, height: 12 }} />
        <Text style={eventsFrequencyStyles.text}>{time}</Text>
      </View>
      <View style={styles.flexRowCenter}>
        <Image src={LocationIco} style={{ width: 12, height: 12 }} />
        <Text style={eventsFrequencyStyles.textBold}>{cameraName}</Text>
        <Text style={eventsFrequencyStyles.text}>{address}</Text>
      </View>
    </View>
  );
};
