import React from 'react';

export const NioIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.749 5.8999C10.8328 5.8999 9.35741 5.97129 7.41798 6.17356L8.41744 17.1082C8.75033 17.1747 9.0774 17.218 9.38236 17.2584L9.38237 17.2584C9.51341 17.2757 9.64037 17.2925 9.76195 17.3104L9.57158 15.1211H13.9145L13.7241 17.2985C14.1286 17.2509 14.5808 17.1914 15.0686 17.0963L16.0681 6.16167C14.1405 5.95939 12.6771 5.8999 11.749 5.8999ZM11.749 12.8128C10.4045 12.8128 9.32172 11.7182 9.32172 10.3856C9.32172 9.04107 10.4164 7.95832 11.749 7.95832C13.0816 7.95832 14.1762 9.05297 14.1762 10.3856C14.1881 11.7182 13.0935 12.8128 11.749 12.8128ZM14.4023 9.02917C14.4023 7.92262 13.2244 7.03025 11.7609 7.03025C10.2974 7.03025 9.11944 7.92262 9.11944 9.02917H8.56022C8.56022 7.62516 9.99992 6.47102 11.7609 6.47102C13.5218 6.47102 14.9615 7.62516 14.9615 9.02917H14.4023ZM22.9931 7.14925C20.6848 6.70902 18.6502 6.42345 16.9606 6.23308L15.9611 16.9892C17.4127 16.6917 19.0428 16.192 20.4825 15.3591C21.0536 15.0379 21.9817 14.3954 22.3267 13.8956C23.2429 12.563 23.4095 9.0054 23.4452 7.70848C23.469 7.43481 23.2667 7.19685 22.9931 7.14925ZM0.516996 7.1493C0.243334 7.2088 0.0529607 7.43486 0.064859 7.72043C0.100554 9.01735 0.279029 12.575 1.1833 13.9076C1.52836 14.4073 2.45643 15.0498 3.02755 15.3711C4.46725 16.204 6.10922 16.7037 7.54892 17.0011L6.56136 6.24503C4.85989 6.42351 2.83718 6.70907 0.516996 7.1493ZM9.88097 10.3856C9.88097 9.35042 10.7258 8.51753 11.749 8.51753C12.7961 8.51753 13.6171 9.36231 13.6171 10.3975C13.6171 11.4326 12.7842 12.2655 11.749 12.2655C10.7139 12.2655 9.88097 11.4088 9.88097 10.3856ZM11.0113 10.3856C11.0113 10.802 11.3445 11.1233 11.749 11.1233C12.1655 11.1233 12.4867 10.802 12.4867 10.3856C12.4867 9.96913 12.1655 9.64788 11.749 9.64788C11.3326 9.64788 11.0113 9.98103 11.0113 10.3856ZM13.0341 19.0001H10.4759L10.1785 15.6686H13.3196L13.0341 19.0001Z'
        fill='white'
      />
    </svg>
  );
};
