import { Instance, types } from "mobx-state-tree";

export const AudioSettings = types
  .model({
    audioVolume: types.optional(types.integer, 95),
    alarmVolume: types.optional(types.integer, 100),
    micMute: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setVolume(value) {
      self.audioVolume = value;
      self.alarmVolume = value;
    },

    toggleMicMute() {
      self.micMute = !self.micMute;
    },
  }));

export interface IAudioSettings extends Instance<typeof AudioSettings> {}
