import React, { Fragment } from "react";
// Material UI imports
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles, createStyles } from "@material-ui/core/styles";
// Other relative imports
import { IFilesStore } from "../../stores/FilesStore";
import { ReqStatus } from "../../types/ReqStatus";

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      height: "300px",
      overflow: "auto",
    },
  })
);

export interface ISoundsListProps {
  filesStore: IFilesStore;
  selected: string | null;
  onChange?: (value: string) => void;
}

export const SoundsList = ({
  selected,
  filesStore,
  onChange,
}: ISoundsListProps) => {
  const classes = useStyles();

  if (filesStore.reqStatus === ReqStatus.Loading) {
    return <CircularProgress />;
  }

  if (filesStore.reqStatus === ReqStatus.Failed) {
    return (
      <Typography>
        Unable to load audio files list. Please try again later.
      </Typography>
    );
  }

  if (filesStore.reqStatus === ReqStatus.Succeeded) {
    return (
      <List className={classes.list}>
        {filesStore.audioFiles.map((audioFile, i) => {
          const isSelected = audioFile.file === selected;
          return (
            <Fragment key={audioFile.file}>
              <ListItem
                selected={isSelected}
                divider={i < filesStore.audioFiles.length - 1}
                onClick={() => onChange?.(audioFile.file)}
                button
              >
                {isSelected && (
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                )}
                <ListItemText
                  inset={!isSelected}
                  primary={
                    <Typography variant="body2">
                      {audioFile.description}
                    </Typography>
                  }
                />
              </ListItem>
            </Fragment>
          );
        })}
      </List>
    );
  }

  return null;
};
