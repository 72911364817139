import { Instance, types, cast } from "mobx-state-tree";

export const AoiV2Settings = types
  .model({
    width: types.optional(types.integer, 14),
    height: types.optional(types.integer, 14),
    vehicleTiles: types.array(types.number),
    personTiles: types.array(types.number),
  })
  .actions((self) => ({
    setDimension(value: number) {
      self.width = value;
      self.height = value;
    },
    setVehicleTiles(tiles: number[]) {
      self.vehicleTiles = cast(tiles);
    },
    setPersonTiles(tiles: number[]) {
      self.personTiles = cast(tiles);
    },
  }));

export interface IAoiV2Settings extends Instance<typeof AoiV2Settings> {}
