import React from 'react';

export function LogoIcon(props) {
  const { color = 'black' } = props;

  return (
    <svg width="119" height="51" viewBox="0 0 119 51" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0)">
        <path d="M0.947266 45.4165H1.99419V50.9502H0.947266V45.4165Z" fill={color} />
        <path
          d="M8.27577 45.4165V50.9502H7.42826L4.3872 47.2112V50.9502H3.39014V45.4165H4.23764L7.2787 49.1555V45.4165H8.27577Z"
          fill={color} />
        <path d="M10.8182 46.264H8.97363V45.4165H13.6598V46.264H11.8153V50.9004H10.7684V46.264H10.8182Z" fill={color} />
        <path
          d="M18.5455 50.0529V50.9004H14.4077V45.4165H18.4458V46.264H15.4546V47.7098H18.0969V48.5573H15.4546V50.0529H18.5455Z"
          fill={color} />
        <path d="M19.5925 45.4165H20.6395V50.0529H23.5309V50.9004H19.6424V45.4165H19.5925Z" fill={color} />
        <path d="M24.2288 45.4165H25.2757V50.0529H28.1672V50.9004H24.2786V45.4165H24.2288Z" fill={color} />
        <path d="M28.915 45.4165H29.9121V50.9502H28.915V45.4165Z" fill={color} />
        <path
          d="M35.1469 48.1086H36.0941V50.3021C35.795 50.5514 35.4958 50.701 35.097 50.8007C34.6982 50.9004 34.3492 51.0001 33.9504 51.0001C33.402 51.0001 32.9035 50.9004 32.4548 50.6511C32.0061 50.4018 31.6571 50.0529 31.4079 49.654C31.1586 49.2054 31.009 48.7567 31.009 48.2083C31.009 47.6599 31.1586 47.1614 31.4079 46.7625C31.6571 46.3139 32.0061 46.0147 32.4548 45.7655C32.9035 45.5162 33.402 45.4165 34.0002 45.4165C34.4489 45.4165 34.8976 45.5162 35.2466 45.6658C35.6454 45.8153 35.9445 46.0646 36.1938 46.3139L35.4958 46.8623C35.097 46.4634 34.5486 46.2142 34.0002 46.2142C33.6014 46.2142 33.2524 46.3139 32.9533 46.4634C32.6542 46.613 32.4049 46.8623 32.2554 47.1614C32.1058 47.4605 32.0061 47.8095 32.0061 48.1584C32.0061 48.5074 32.1058 48.8564 32.2554 49.1555C32.4049 49.4546 32.6542 49.7039 32.9533 49.8535C33.2524 50.003 33.6014 50.1027 34.0002 50.1027C34.4489 50.1027 34.7979 50.003 35.1469 49.8036V48.1086Z"
          fill={color} />
        <path
          d="M41.528 50.0529V50.9004H37.3901V45.4165H41.3784V46.264H38.3872V47.7098H41.0294V48.5573H38.3872V50.0529H41.528Z"
          fill={color} />
        <path
          d="M47.4606 45.4165V50.9502H46.6131L43.572 47.2112V50.9502H42.575V45.4165H43.3726L46.4137 49.1555V45.4165H47.4606Z"
          fill={color} />
        <path d="M50.0028 46.264H48.1582V45.4165H52.8444V46.264H50.9998V50.9004H49.9529V46.264H50.0028Z" fill={color} />
        <path
          d="M55.7859 45.4165H58.1789C58.7771 45.4165 59.2756 45.5162 59.7243 45.7655C60.173 46.0147 60.522 46.3139 60.7712 46.7127C61.0205 47.1115 61.1701 47.6101 61.1701 48.1584C61.1701 48.7068 61.0205 49.2054 60.7712 49.6042C60.522 50.003 60.173 50.352 59.7243 50.5514C59.2756 50.8007 58.7272 50.9004 58.1789 50.9004H55.7859V45.4165ZM58.1789 50.0529C58.5777 50.0529 58.9267 49.9532 59.2258 49.8036C59.5249 49.654 59.7742 49.4048 59.9237 49.1555C60.0733 48.8564 60.173 48.5573 60.173 48.1584C60.173 47.7596 60.0733 47.4605 59.9237 47.1614C59.7742 46.8623 59.5249 46.6628 59.2258 46.5133C58.9267 46.3637 58.5777 46.264 58.1789 46.264H56.8328V50.0529H58.1789Z"
          fill={color} />
        <path
          d="M66.4047 50.0529V50.9004H62.2668V45.4165H66.305V46.264H63.3138V47.7098H65.956V48.5573H63.3138V50.0529H66.4047Z"
          fill={color} />
        <path d="M68.6978 46.264H66.8533V45.4165H71.5395V46.264H69.6949V50.9004H68.648V46.264H68.6978Z" fill={color} />
        <path
          d="M76.4252 50.0529V50.9004H72.2874V45.4165H76.3255V46.264H73.3343V47.7098H75.9765V48.5573H73.3343V50.0529H76.4252Z"
          fill={color} />
        <path
          d="M81.0617 50.9004L79.915 49.3051C79.8652 49.3051 79.8153 49.3051 79.7156 49.3051H78.4693V50.9004H77.4224V45.4165H79.7156C80.2141 45.4165 80.613 45.5162 80.9619 45.6658C81.3109 45.8153 81.61 46.0646 81.7596 46.3637C81.959 46.6628 82.0587 47.0118 82.0587 47.4106C82.0587 47.8095 81.959 48.1584 81.7596 48.4576C81.5602 48.7567 81.2611 48.9561 80.9121 49.1057L82.1584 50.9004H81.0617ZM80.7127 46.5631C80.4634 46.3637 80.1643 46.264 79.7156 46.264H78.5191V48.4576H79.7156C80.1643 48.4576 80.5133 48.3579 80.7127 48.1584C80.9619 47.959 81.0617 47.7098 81.0617 47.3608C81.0617 47.0118 80.9619 46.7625 80.7127 46.5631Z"
          fill={color} />
        <path
          d="M86.7948 50.9004L85.6482 49.3051C85.5983 49.3051 85.5485 49.3051 85.4488 49.3051H84.2024V50.9004H83.1555V45.4165H85.4488C85.9473 45.4165 86.3461 45.5162 86.6951 45.6658C87.0441 45.8153 87.3432 46.0646 87.4928 46.3637C87.6922 46.6628 87.7919 47.0118 87.7919 47.4106C87.7919 47.8095 87.6922 48.1584 87.4928 48.4576C87.2933 48.7567 86.9942 48.9561 86.6453 49.1057L87.8916 50.9004H86.7948ZM86.396 46.5631C86.1467 46.3637 85.8476 46.264 85.3989 46.264H84.2024V48.4576H85.3989C85.8476 48.4576 86.1966 48.3579 86.396 48.1584C86.6453 47.959 86.745 47.7098 86.745 47.3608C86.745 47.0118 86.6453 46.7625 86.396 46.5631Z"
          fill={color} />
        <path
          d="M93.0265 50.0529V50.9004H88.8887V45.4165H92.9268V46.264H89.9356V47.7098H92.5778V48.5573H89.9356V50.0529H93.0265Z"
          fill={color} />
        <path
          d="M98.9591 45.4165V50.9502H98.1116L95.0706 47.2112V50.9502H94.0735V45.4165H94.921L97.962 49.1555V45.4165H98.9591Z"
          fill={color} />
        <path
          d="M101.452 50.6511C101.003 50.4018 100.654 50.0529 100.405 49.654C100.155 49.2054 100.006 48.7567 100.006 48.2083C100.006 47.6599 100.155 47.1614 100.405 46.7625C100.654 46.3139 101.003 46.0147 101.452 45.7655C101.9 45.5162 102.399 45.4165 102.947 45.4165C103.396 45.4165 103.795 45.5162 104.194 45.6658C104.543 45.8153 104.891 46.0646 105.141 46.3637L104.493 46.962C104.094 46.5133 103.595 46.3139 102.997 46.3139C102.598 46.3139 102.299 46.4136 102 46.5631C101.701 46.7127 101.452 46.962 101.302 47.2611C101.152 47.5602 101.053 47.9092 101.053 48.2581C101.053 48.6071 101.152 48.9561 101.302 49.2552C101.452 49.5543 101.701 49.8036 102 49.9532C102.299 50.1027 102.648 50.2024 102.997 50.2024C103.595 50.2024 104.094 50.003 104.493 49.5543L105.141 50.2024C104.891 50.5015 104.543 50.7508 104.194 50.9004C103.795 51.0499 103.396 51.1496 102.947 51.1496C102.399 51.0001 101.9 50.8505 101.452 50.6511Z"
          fill={color} />
        <path
          d="M110.176 50.0529V50.9004H106.038V45.4165H110.076V46.264H107.085V47.7098H109.727V48.5573H107.085V50.0529H110.176Z"
          fill={color} />
        <path d="M13.6101 5.13475L18.3462 0.398682L35.9943 18.0468L33.6512 20.4397L31.2582 22.7828L13.6101 5.13475Z"
              fill="#FF0046" />
        <g opacity="0.7">
          <path opacity="0.7"
                d="M13.6101 30.9589L18.3462 35.7448L35.9943 18.0469L33.6512 15.7038L31.2582 13.3108L13.6101 30.9589Z"
                fill="#FF0046" />
        </g>
        <path
          d="M1.34604 7.52786C0.44868 6.68035 0 5.63343 0 4.3871C0 3.14076 0.44868 2.09384 1.34604 1.24633C2.29326 0.398827 3.43988 0 4.88563 0C6.33138 0 7.52786 0.398827 8.42522 1.19648C9.32258 1.99413 9.77126 2.9912 9.77126 4.23754C9.77126 5.53372 9.32258 6.6305 8.42522 7.47801C7.52786 8.32551 6.33138 8.77419 4.88563 8.77419C3.43988 8.77419 2.29326 8.37537 1.34604 7.52786ZM0.947214 12.563H8.82405V35.7449H0.947214V12.563Z"
          fill={color} />
        <path
          d="M29.2142 0.398682H29.613C33.4517 0.398682 36.8418 1.14648 39.7831 2.59223C42.7245 4.03798 45.0177 6.13182 46.6629 8.77405C48.3081 11.4163 49.1057 14.5072 49.1057 18.0468C49.1057 21.5864 48.3081 24.6773 46.6629 27.3195C45.0177 29.9617 42.7245 32.0556 39.7831 33.5013C36.8418 34.9471 33.4517 35.6949 29.613 35.6949H13.6101V16.0526L20.3403 16.0028V29.0145H29.2641C32.8037 29.0145 35.5954 28.0174 37.7391 26.0732C39.833 24.1289 40.8799 21.4368 40.8799 18.0966C40.8799 14.7565 39.833 12.0644 37.7391 10.1201C35.6453 8.17581 32.8037 7.17874 29.2641 7.17874L29.2142 0.398682Z"
          fill={color} />
        <path
          d="M68.4985 34.3987C67.7009 34.9471 66.7537 35.3958 65.6569 35.6949C64.5103 35.994 63.3636 36.1436 62.1173 36.1436C58.8768 36.1436 56.3842 35.2961 54.6393 33.6509C52.8944 32.0058 51.9971 29.563 51.9971 26.3723V2.59229H59.824V9.22278H66.6041V15.255H59.824V26.3225C59.824 27.4691 60.1232 28.3665 60.7214 28.9647C61.3196 29.563 62.1173 29.9119 63.2141 29.9119C64.4604 29.9119 65.5073 29.563 66.4047 28.9149L68.4985 34.3987Z"
          fill={color} />
        <path
          d="M97.9121 24.3782H77.3725C77.7215 26.0732 78.6188 27.3694 80.0147 28.3664C81.4106 29.3635 83.1056 29.8122 85.1496 29.8122C86.5455 29.8122 87.7918 29.6128 88.8886 29.1641C89.9854 28.7653 90.9825 28.0673 91.9297 27.17L96.1174 31.7066C93.5748 34.648 89.8358 36.0937 84.9003 36.0937C81.8593 36.0937 79.1174 35.4955 76.7742 34.299C74.4311 33.1025 72.5866 31.4574 71.3402 29.3137C70.044 27.17 69.4458 24.777 69.4458 22.0849C69.4458 19.4427 70.0939 17.0497 71.3402 14.906C72.5866 12.7623 74.3314 11.1172 76.525 9.9207C78.7185 8.72422 81.2112 8.12598 83.9033 8.12598C86.5455 8.12598 88.9883 8.67436 91.132 9.82099C93.2757 10.9676 94.9707 12.6128 96.2171 14.7565C97.4634 16.9002 98.0616 19.3928 98.0616 22.1846C98.0616 22.3342 98.0118 23.082 97.9121 24.3782ZM79.5162 15.6538C78.3197 16.6509 77.5719 18.0468 77.2728 19.7917H90.6335C90.3842 18.0967 89.6364 16.7008 88.3901 15.7037C87.1936 14.6568 85.698 14.1582 83.9531 14.1582C82.2083 14.1084 80.7127 14.6069 79.5162 15.6538Z"
          fill={color} />
        <path
          d="M113.466 9.17309C115.062 8.525 116.906 8.17603 119 8.17603V15.4546C118.103 15.4048 117.554 15.3549 117.255 15.3549C115.012 15.3549 113.217 16.003 111.971 17.2493C110.675 18.4957 110.076 20.3901 110.076 22.9326V35.7449H102.199V8.57485H109.677V12.1643C110.625 10.8681 111.871 9.87104 113.466 9.17309Z"
          fill={color} />
        <path d="M13.6101 0.398682H20.2905V16.0526H16.9503H13.6101V0.398682Z" fill="#FF0046" />
        <path d="M29.2142 0.398682V7.12889H13.6101V3.73886V0.398682H29.2142Z" fill="#FF0046" />
      </g>
    </svg>
  );
}