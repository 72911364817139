import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, useTheme, useMediaQuery } from '@material-ui/core';
import ReportDownloader from '../Report/ReportDownloader';
import PanelDateRange from './PanelDateRange';

export const PanelHeaderBottomStyles = makeStyles((theme) => ({
  root: {
    padding: '0 48px',
    // marginLeft: '-24px',
    [theme.breakpoints.down('md')]: {
      padding: '0 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 0',
    },
  },
  inner: {
    background: '#3E3E3E',
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.25)',
    borderRadius: 20,
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
    },
    overflow: 'hidden',
  },
  downloadReport: {
    // margin: -12,
    // padding: 24,
    background: '#252525',
  },
}));

function PanelHeaderBottom(props) {
  const classes = PanelHeaderBottomStyles(props);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className={classes.root}>
      <Grid container className={classes.inner}>
        <Grid container spacing={matches ? 0 : 3}>
          <Grid item xs={12} md={matches ? 8 : 9}>
            <PanelDateRange />
          </Grid>
          <Grid item xs={12} md={matches ? 4 : 3}>
            <Box
              display='flex'
              p={3}
              height={1}
              className={classes.downloadReport}
            >
              <ReportDownloader />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PanelHeaderBottom;
