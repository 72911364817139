import React, { useState, useEffect } from "react";
// Material UI imports
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// Other relative imports
import { AreaOfInterestDialog } from "./AreaOfInterestDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pirValue: {
      fontWeight: "bold",
    },
    divider: {
      margin: theme.spacing(1, 0),
    },
  })
);

const useSliderStyles = makeStyles(() =>
  createStyles({
    thumb: {
      height: 20,
      width: 20,
      marginTop: -9,
      marginLeft: -11,
    },
  })
);

export interface IEventDetectionProps {
  thumbnailUrl: string;
  pir?: number;
  vehicleTiles?: number[];
  personTiles?: number[];
  onPirChange?: (value: number) => void;
  onVehicleTilesChange?: (tiles: number[]) => void;
  onPersonTilesChange?: (tiles: number[]) => void;
}

export const EventDetection = ({
  thumbnailUrl,
  pir = 0.5,
  vehicleTiles = [],
  personTiles = [],
  onPirChange,
  onVehicleTilesChange,
  onPersonTilesChange,
}: IEventDetectionProps) => {
  const classes = useStyles();
  const [pirSliderValue, setPirSliderValue] = useState<number>(pir);
  const [modal, setModal] = useState<"people" | "vehicles" | "">("");

  useEffect(() => {
    setPirSliderValue(pir);
  }, [pir]);

  return (
    <>
      <Box width="100%">
        <List>
          <ListItem onClick={() => setModal("people")} button divider>
            <ListItemText
              primary={
                <Typography variant="body2">People Area of Interest</Typography>
              }
            />
          </ListItem>
          <ListItem onClick={() => setModal("vehicles")} button divider>
            <ListItemText
              primary={
                <Typography variant="body2">
                  Vehicles Area of Interest
                </Typography>
              }
            />
          </ListItem>
        </List>

        <Typography variant="body2" gutterBottom>
          PIR{" "}
          <Typography
            display="inline"
            variant="body2"
            className={classes.pirValue}
          >
            {Math.floor(pirSliderValue * 100)}%
          </Typography>
        </Typography>
        <Grid container spacing={2}>
          <Grid item container>
            <Grid item>
              <Typography variant="body2">0%</Typography>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Typography variant="body2">100%</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box margin="0px 24px">
              <Slider
                value={pirSliderValue}
                classes={useSliderStyles()}
                onChange={(_, newValue) =>
                  setPirSliderValue(newValue as number)
                }
                onChangeCommitted={(_, value) => onPirChange?.(value as number)}
                min={0}
                max={1}
                step={0.01}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <AreaOfInterestDialog
        open={modal === "people"}
        title="People Area of Interest"
        onClose={() => setModal("")}
        thumbnailUrl={thumbnailUrl}
        initTiles={personTiles}
        onSave={onPersonTilesChange}
      />
      <AreaOfInterestDialog
        open={modal === "vehicles"}
        title="Vehicles Area of Interest"
        onClose={() => setModal("")}
        thumbnailUrl={thumbnailUrl}
        initTiles={vehicleTiles}
        onSave={onVehicleTilesChange}
      />
    </>
  );
};
