import React, { useRef, useState } from 'react';
import { BarChart, Bar, XAxis } from 'recharts';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const data = [
  {
    name: '12',
    pv: 2400,
  },
  {
    name: '1',
    pv: 1398,
  },
  {
    name: '2',
    pv: 9800,
  },
  {
    name: '3',
    pv: 3908,
  },
  {
    name: '4',
    pv: 4800,
  },
  {
    name: '6',
    pv: 3800,
  },
  {
    name: '7',
    pv: 4300,
  },
  {
    name: '8',
    pv: 4300,
  },
  {
    name: '9',
    pv: 4300,
  },
  {
    name: '10',
    pv: 4300,
  },
  {
    name: '11',
    pv: 4300,
  },
  {
    name: '12',
    pv: 2400,
  },
  {
    name: '1',
    pv: 1398,
  },
  {
    name: '2',
    pv: 9800,
  },
  {
    name: '3',
    pv: 3908,
  },
  {
    name: '4',
    pv: 4800,
  },
  {
    name: '6',
    pv: 3800,
  },
  {
    name: '7',
    pv: 4300,
  },
  {
    name: '8',
    pv: 4300,
  },
  {
    name: '9',
    pv: 4300,
  },
  {
    name: '10',
    pv: 4300,
  },
  {
    name: '11',
    pv: 4300,
  },
];

export const ActivityChartStyles = makeStyles<Theme, {}>((theme) => ({
  container: {
    width: '99%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  periods: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  period: {
    width: '49.3%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  periodText: {
    fontSize: 10,
    lineHeight: 1.2,
    fontWeight: 500,
    marginTop: 1,
    color: '#555555',
  },
  periodLine: {
    width: '100%',
    height: 1,
    backgroundColor: '#555555',
    margin: 0,
  },
}));

export const ActivityChart = () => {
  const classes = ActivityChartStyles({});
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const container = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState(0);

  React.useEffect(() => {
    if (container.current) {
      if (matches) {
        setChartWidth(container.current.offsetWidth);
      } else {
        setChartWidth(container.current.offsetWidth - 24);
      }
    }
  }, [container, matches]);

  return (
    <div className={classes.container} ref={container}>
      <BarChart width={chartWidth} height={94} data={data} barSize={4}>
        <XAxis
          dataKey='name'
          axisLine={false}
          tickLine={false}
          tick={{ fontFamily: 'Barlow', fontWeight: 500, fontSize: 12 }}
        />
        <Bar
          dataKey='pv'
          fill='#fff'
          background={{ fill: '#222222', radius: 5 }}
          radius={5}
          stroke='#000'
        />
      </BarChart>
      <div className={classes.periods}>
        <div className={classes.period}>
          <hr className={classes.periodLine} />
          <span className={classes.periodText}>AM</span>
        </div>
        <div className={classes.period}>
          <hr className={classes.periodLine} />
          <span className={classes.periodText}>PM</span>
        </div>
      </div>
    </div>
  );
};

export default ActivityChart;
