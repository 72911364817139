import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'mobx-react';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { router } from './stores/NavigationStore';
import setupRootStore from './stores/setupRootStore';
import { App } from './App';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './services/theme';
import { MstProvider } from './stores/RootStore';
import { SnackbarProvider } from 'notistack';
import config from './config';

const rootStore = setupRootStore();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <MstProvider value={rootStore}>
      <Provider rootStore={rootStore} {...rootStore} router={router}>
        <SnackbarProvider maxSnack={3}>
          <BrowserRouter basename={config.ROOT_PATH}>
            <CssBaseline />
            <App />
          </BrowserRouter>
        </SnackbarProvider>
      </Provider>
    </MstProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
