import React, { FC, useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReportPieChart } from './ReportPieChart';

import { colors } from '../../services/colors';

// Create styles
export const WorkTimeReportStyles = makeStyles({
  container: {
    display: 'flex',
    verticalAlign: 'baseline',
    justifyContent: 'space-between',
    paddingTop: 30,
    paddingBottom: 30,
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  flexColEnd: {
    alignItems: 'flex-end',
  },
  mainText: {
    fontFamily: 'Montserrat',
    fontSize: 38,
    fontWeight: 600,
    color: colors.green,
    lineHeight: '46px',
  },
  text: {
    fontFamily: 'Barlow',
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '29px',
  },
  textBold: {
    fontWeight: 600,
    color: colors.green,
  },
  redText: {
    color: colors.red,
  },
});

export interface WorkTimeReportProps {
  working: number;
  down: number;
  hoursVideo: number;
  uptime: number;
}

export const WorkTimeReport: FC<WorkTimeReportProps> = ({
  working,
  down,
  hoursVideo,
  uptime,
}) => {
  const classes = WorkTimeReportStyles({});
  const [hoursVideoChartValue, setHoursVideoChartValue] = useState(0);

  const chartData = [
    { name: 'Working', value: working },
    { name: 'Down', value: down },
  ];

  const mockHoursVideo = 362;

  useEffect(() => {
    if (working && mockHoursVideo) {
      setHoursVideoChartValue(
        +(((mockHoursVideo * 100) / working) * 3.6).toFixed(2)
      );
    }
  }, [working, mockHoursVideo]);

  return (
    <Box className={classes.container}>
      <Box className={`${classes.flexCol} ${classes.flexColEnd}`}>
        <Typography
          variant='h4'
          className={`${classes.mainText} ${classes.redText}`}
        >{`${mockHoursVideo} h`}</Typography>
        <span className={classes.text}>
          of videos
          <br /> recorded
        </span>
      </Box>

      <ReportPieChart
        firstChartData={chartData}
        secondChartValue={hoursVideoChartValue}
      />

      <Box className={classes.flexCol}>
        <Box>
          <span className={classes.mainText}>{`${working} h `}</span>
          <span className={classes.text}>working</span>
        </Box>
        <Box>
          <span
            className={`${classes.text} ${classes.textBold}`}
          >{`${down} h `}</span>
          <span className={classes.text}>down</span>
        </Box>
      </Box>
      <Box>
        <span className={classes.mainText}>{`${uptime}% `}</span>
        <span className={classes.text}>uptime</span>
      </Box>
    </Box>
  );
};
