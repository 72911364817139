import React, { FC } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';

import { colors } from '../../services/colors';
import Logo from './img/logo.png';
import { PDFEventsFrequency } from './PDFEventsFrequency';
import { PDFCameraCard } from './PDFCameraCard';

import {
  ActivityReportInterface,
  ActivityChartsInterface,
} from '../../stores/reportStore';

// Register font
Font.register({
  family: 'Barlow',
  fonts: [
    { src: process.env.PUBLIC_URL + '/fonts/Barlow-Regular.ttf' },
    {
      src: process.env.PUBLIC_URL + '/fonts/Barlow-Medium.ttf',
      fontWeight: 500,
    },
    {
      src: process.env.PUBLIC_URL + '/fonts/Barlow-Bold.ttf',
      fontWeight: 700,
    },
  ],
});

Font.register({
  family: 'Montserrat',
  fonts: [
    {
      src: process.env.PUBLIC_URL + '/fonts/Montserrat-SemiBold.ttf',
      fontWeight: 600,
    },
    {
      src: process.env.PUBLIC_URL + '/fonts/Montserrat-Bold.ttf',
      fontWeight: 700,
    },
  ],
});

// Create styles
export const styles = StyleSheet.create({
  page: {
    padding: '40px 45px 30px 45px',
    fontFamily: 'Barlow',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  title: {
    fontWeight: 500,
    fontSize: 16,
  },
  headerSubtitle: {
    fontWeight: 500,
    fontSize: 7,
  },
  workTimeReportContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingTop: 15,
    paddingBottom: 15,
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text_M_l: {
    fontFamily: 'Montserrat',
    fontSize: 19,
    fontWeight: 600,
    color: colors.green,
  },
  text_m: {
    fontWeight: 500,
    fontSize: 12,
  },
  textBoldGreen: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    color: colors.green,
  },
  activity: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 15,
    paddingBottom: 35,

    borderBottom: 1,
    borderBottomColor: '#999999',
    borderStyle: 'dashed',
  },
  marker: {
    color: '#fff',
    backgroundColor: '#000000',

    borderRadius: 2,
    marginRight: 4,
    padding: '0 4px',

    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 11,
  },
  cameraReport: {
    paddingTop: 11,
  },
  activityChart: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 22,
    marginBottom: 17,
  },
  activityLine: {
    width: '49.3%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    borderTop: 1,
    borderTopColor: '#555555',
  },
});

interface PDFReportProps {
  activities: ActivityReportInterface[];
  activityCharts: ActivityChartsInterface;
  period: string;
  workReport: {
    working: number;
    down: number;
    hoursVideo: number;
    uptime: number;
  };
  reportStatus: string;
}

export const PDFReport: FC<PDFReportProps> = ({
  activities,
  activityCharts,
  period,
  workReport,
  reportStatus,
}) => {
  return (
    <Document>
      <Page size='A4' wrap>
        <View style={styles.page}>
          <View style={styles.header}>
            <Image source={Logo} style={{ width: '60px', height: '26px' }} />
            <View style={styles.headerText}>
              <Text style={[styles.title, { marginBottom: 6 }]}>
                Starlight Security Month Report
              </Text>
              <Text style={styles.headerSubtitle}>{period}</Text>
              <Text style={styles.headerSubtitle}>
                starlightsecurity.idter.com
              </Text>
            </View>
          </View>
          <View style={styles.workTimeReportContainer}>
            <View style={[styles.flexCol, { alignItems: 'flex-end' }]}>
              <Text
                style={[styles.text_M_l, { color: colors.red }]}
              >{`${workReport.hoursVideo} h`}</Text>
              <Text style={styles.text_m}>of videos</Text>
              <Text style={styles.text_m}>recorded</Text>
            </View>
            {activityCharts.reportPieChart && (
              <Image
                src={activityCharts.reportPieChart}
                style={{ width: 105, height: 105 }}
              />
            )}
            <View style={styles.flexCol}>
              <View style={styles.flexRowCenter}>
                <Text
                  style={styles.text_M_l}
                >{`${workReport.working} h `}</Text>
                <Text style={styles.text_m}>working</Text>
              </View>
              <View style={styles.flexRowCenter}>
                <Text
                  style={[styles.text_m, styles.textBoldGreen]}
                >{`${workReport.down} h `}</Text>
                <Text style={styles.text_m}>down</Text>
              </View>
            </View>
            <View style={styles.flexRowCenter}>
              <Text style={styles.text_M_l}>{`${workReport.uptime}% `}</Text>
              <Text style={styles.text_m}>uptime</Text>
            </View>
          </View>
          <View style={styles.activity}>
            <Text style={[styles.title, { marginBottom: 9 }]}>
              Activity Summary
            </Text>
            <View style={styles.flexRowCenter}>
              <View style={[styles.flexRowCenter, { marginRight: 41 }]}>
                <Text style={[styles.text_m, styles.marker]}>237</Text>
                <Text style={styles.text_m}>events triggered overall</Text>
              </View>
              <View style={styles.flexRowCenter}>
                <Text style={[styles.text_m, styles.marker]}>204</Text>
                <Text style={styles.text_m}>video verified alarms</Text>
              </View>
            </View>
            <View style={styles.activityChart}>
              {activityCharts.activityChart && (
                <Image
                  src={activityCharts.activityChart}
                  style={{ width: '100%', height: 'auto' }}
                />
              )}
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <View style={styles.activityLine}>
                  <Text style={[styles.headerSubtitle, { color: '#555555' }]}>
                    AM
                  </Text>
                </View>
                <View style={styles.activityLine}>
                  <Text style={[styles.headerSubtitle, { color: '#555555' }]}>
                    PM
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={[styles.flexRowCenter, { justifyContent: 'flex-start' }]}
            >
              <PDFEventsFrequency
                title='Most Events'
                time='11 pm to 2 am (night)'
                cameraName='Back Entrance'
                address='1254 Cherry Lane'
              />
              <PDFEventsFrequency
                title='Least Events (greater than 0)'
                time='1 pm to 2 pm (midday)'
                cameraName='Side Entrance 1'
                address='3601 Old Airport Rd.'
              />
            </View>
          </View>
          {reportStatus !== 'hide' && (
            <View style={styles.cameraReport}>
              <Text style={styles.title}>
                Niō Nodes Arranged by Events number
              </Text>
              {activities.map((activity, id) => (
                <PDFCameraCard
                  key={id}
                  thumbnailBase64={activity.thumbnailBase64}
                  workingHours={activity.workingHours}
                  events={activity.events}
                  cameraName={activity.cameraName}
                  companyName={activity.companyName}
                  uptime={activity.uptime}
                  imgIsVisible={reportStatus === 'previews'}
                />
              ))}
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
};
