import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Box from "@material-ui/core/Box";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    switchLabel: {
      display: "flex",
      alignItems: "center",
    },
  })
);

export interface ICameraProps {
  quality?: boolean;
  nightVision?: boolean;
  hdr?: boolean;
  onQualityChange?: (checked: boolean) => void;
  onNightVisionChange?: (checked: boolean) => void;
  onHdrChange?: (checked: boolean) => void;
}

export const Camera = ({
  quality,
  nightVision,
  hdr,
  onQualityChange,
  onNightVisionChange,
  onHdrChange,
}: ICameraProps) => {
  const classes = useStyles();

  return (
    <Box width="100%">
      <Grid component="label" container spacing={1}>
        <Grid className={classes.switchLabel} xs={12} item >
          <Typography variant="body2">Video Quality</Typography>
        </Grid>

        <Grid className={classes.switchLabel} item>
          <Typography variant="body2">HD</Typography>
        </Grid>
        <Grid item>
          <Switch
            checked={quality}
            onChange={(_, checked) => onQualityChange?.(checked)}
          />
        </Grid>
        <Grid className={classes.switchLabel} item>
          <Typography variant="body2">4K</Typography>
        </Grid>
      </Grid>

      <Grid component="label" container justify="space-between">
        <Grid className={classes.switchLabel} item>
          <Typography variant="body2">Night Vision</Typography>
        </Grid>
        <Grid item>
          <Switch
            checked={nightVision}
            onChange={(_, checked) => onNightVisionChange?.(checked)}
          />
        </Grid>
      </Grid>

      <Grid component="label" container justify="space-between">
        <Grid className={classes.switchLabel} item>
          <Typography variant="body2">HDR</Typography>
        </Grid>
        <Grid item>
          <Switch
            checked={hdr}
            onChange={(_, checked) => onHdrChange?.(checked)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
