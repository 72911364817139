import React from 'react';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Area, ComposedChart, ResponsiveContainer, Tooltip } from 'recharts';
import { Grid, Box, CircularProgress } from '@material-ui/core';
import BoxedNumber from './BoxedNumber';
import { colors } from '../../services/colors';

export const EventsChartStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    color: '#ffffff',
  },

  title: {
    marginBottom: 12,
    fontFamily: 'Montserrat, Helvetica Neue, sans-serif',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '29px',
    color: '#F4F4F4',
  },
  subtitle: {
    marginBottom: 15,
    fontFamily: 'Montserrat, Helvetica Neue, sans-serif',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '22px',
    color: '#F4F4F4',
  },
  items: {
    fontSize: 12,
    lineHeight: '15px',
  },
  itemsLabel: {
    marginLeft: 5,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '14px',
  },
  chartText: {
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '12px',
    color: '#999999',
    marginRight: '2px',
    '&:last-child': {
      marginRight: 0,
      marginLeft: '2px',
    },
  },
  line: {
    width: '100%',
    height: 1,
    backgroundColor: '#555555',
    marginInline: 0,
    marginBlock: 2,
    borderWidth: 0,
  },
}));

function EventsChart(props) {
  const classes = EventsChartStyles(props);

  const { stats } = props;

  if (!stats) {
    return <CircularProgress />;
  }

  const getPercent = (value: number, total: number) => {
    const percent = (value / total) * 100;

    if (percent.toString().split('.')[1]) {
      return percent.toFixed(2);
    }
    return percent;
  };

  return (
    <Grid container>
      <Grid container className={classes.root}>
        <Typography className={classes.title}>
          {getPercent(stats.deterredIntrusions, stats.intrusions)}% Deterrence
        </Typography>

        <Box display='flex' flexDirection='row' mb={1}>
          <BoxedNumber
            number={stats.intrusions}
            backgroundColor={colors.gray_50}
            textColor='white'
            width={42}
          />
          <Typography variant='body1' color='textPrimary'>
            &nbsp;Intrusions
          </Typography>
        </Box>
        <Box display='flex' flexDirection='row' mb={1}>
          <BoxedNumber
            number={stats.deterredIntrusions}
            backgroundColor={colors.red}
            textColor='white'
            width={42}
          />
          <Typography variant='body1' color='textPrimary'>
            &nbsp;Deterred Intrusions
          </Typography>
        </Box>
        <Box display='flex' flexDirection='row' mb={1}>
          <BoxedNumber
            number={stats.alarms}
            backgroundColor={colors.purple}
            textColor='white'
            width={42}
          />
          <Typography variant='body1' color='textPrimary'>
            &nbsp;Video Verified Alarms
          </Typography>
        </Box>
        {console.log(stats.eventsByDay)}
        <ResponsiveContainer width='99%' height={130}>
          <ComposedChart data={stats.eventsByDay}>
            <defs>
              <linearGradient
                id='red_gradient'
                x1='147'
                y1='2.7'
                x2='146'
                y2='200'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#FF0046' />
                <stop offset='0.671875' stopColor='#FF0046' stopOpacity='0' />
              </linearGradient>
            </defs>
            <Area
              type='monotone'
              name='Recordings'
              dataKey='recordings'
              fill={'url(#red_gradient)'}
              stroke={colors.red}
              fillOpacity={0.3}
            />
            <Area
              type='monotone'
              dataKey='engagements'
              stroke={colors.purple}
              fill='none'
            />
            <Area
              type='monotone'
              dataKey='missedDetection'
              stroke={colors.gray_50}
              fill='none'
            />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} />
          </ComposedChart>
        </ResponsiveContainer>
        <Box display='flex' mt='3px' mb={3}>
          <span className={classes.chartText}>
            {format(new Date(stats.eventsByDay[0].hour), 'MM/dd/yyyy')}
          </span>
          <hr className={classes.line} />
          <span className={classes.chartText}>
            {format(
              new Date(stats.eventsByDay[stats.eventsByDay.length - 1].hour),
              'MM/dd/yyyy'
            )}
          </span>
        </Box>
      </Grid>
      <Grid container className={classes.items}>
        <Grid item xs={6}>
          <Typography className={classes.subtitle}>
            {getPercent(stats.mtd.deterredIntrusions, stats.mtd.intrusions)}%
            MTD
          </Typography>

          <Box display='flex' flexDirection='row' mb={'4px'}>
            <BoxedNumber
              number={stats.mtd?.intrusions}
              backgroundColor={colors.gray_50}
              textColor='white'
              width={46}
            />
            <Typography className={classes.itemsLabel}>Intrusions</Typography>
          </Box>
          <Box display='flex' flexDirection='row' mb={'4px'}>
            <BoxedNumber
              number={stats.mtd?.deterredIntrusions}
              backgroundColor={colors.red}
              textColor='white'
              width={46}
            />
            <Typography className={classes.itemsLabel}>Deterred</Typography>
          </Box>
          <Box display='flex' flexDirection='row'>
            <BoxedNumber
              number={stats.mtd?.alarms}
              backgroundColor={colors.purple}
              textColor='white'
              width={46}
            />
            <Typography className={classes.itemsLabel}>Alarms</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.subtitle}>
            {getPercent(stats.ytd.deterredIntrusions, stats.ytd.intrusions)}%
            YTD
          </Typography>

          <Box display='flex' flexDirection='row' mb={'4px'}>
            <BoxedNumber
              number={stats.ytd?.intrusions}
              backgroundColor={colors.gray_50}
              textColor='white'
              width={46}
            />
            <Typography className={classes.itemsLabel}>Intrusions</Typography>
          </Box>
          <Box display='flex' flexDirection='row' mb={'4px'}>
            <BoxedNumber
              number={stats.ytd?.deterredIntrusions}
              backgroundColor={colors.red}
              textColor='white'
              width={46}
            />
            <Typography className={classes.itemsLabel}>Deterred</Typography>
          </Box>
          <Box display='flex' flexDirection='row'>
            <BoxedNumber
              number={stats.ytd?.alarms}
              backgroundColor={colors.purple}
              textColor='white'
              width={46}
            />
            <Typography className={classes.itemsLabel}>Alarms</Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EventsChart;
