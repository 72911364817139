import {withStyles} from '@material-ui/core';
import {RecordDescription} from './Events/consts';
import {getInfoIcon} from '../services/videoItemUtils';
import React from 'react';

const styles = {
  icon: {
    width: 18,
    height: 18,
  },
};


export const DescriptionIcon = withStyles(styles)(({classes, description}: {
  classes: any,
  description: RecordDescription
}) => {
  if (!description) return null;

  const icons = description.split(',');
  return (
    <>
      {icons.map(icon => {
        const Icon = getInfoIcon(icon as RecordDescription);
        return Icon && <Icon key={icon} classes={{root: classes.icon}}/>;
      })}
    </>
  );
});
