import React, { useEffect } from 'react';
import './App.css';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
// import Login from 'components/Account/Login';
import { useStores } from './stores/RootStore';
import { CircularProgress } from '@material-ui/core';
import { Dashboard } from './pages/Dashboard';
import { Cameras } from './pages/Cameras';
import { Map } from './pages/Map';
import { Report } from './components/Report';

export const App = observer(() => {
  const { authStore, appLoaded } = useStores();
  const history = useHistory();
  const location = useLocation();
  const { verifySession, saveDesiredPath } = authStore;
  const { push } = history;

  useEffect(() => {
    verifySession().then((verified) => {
      if (!verified) {
        saveDesiredPath(location.pathname, location.search);
        // push('/login')
      }
    });
  }, [verifySession, saveDesiredPath, push]); // eslint-disable-line react-hooks/exhaustive-deps

  if (appLoaded) {
    return (
      <Switch>
        {/* <Route path="/login" component={Login} /> */}
        <Route path='/dashboard' component={Dashboard} />
        <Route path='/cameras' component={Cameras} />
        <Route path='/map' component={Map} />
        <Route path='/report' component={Report} />
        <Redirect to='/dashboard' />
      </Switch>
    );
  }
  return <CircularProgress />;
});
