import React from "react";
import { inject, observer } from "mobx-react";
import InfiniteScroll from "react-infinite-scroller";
import {
  CircularProgress,
  createStyles,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import {
  RecordingsStoreInterface,
} from "stores/recordingsStore";
import { FilterStoreInterface } from "stores/filterStore";
import CamerasList from "./CamerasList";

const styles = () =>
  createStyles({
    root: {
      padding: "24px 30px",
    },
  });

interface EventsProps extends WithStyles<typeof styles> {}

interface InjectedProps extends EventsProps {
  recordingsStore: RecordingsStoreInterface;
  filterStore: FilterStoreInterface;
}

@inject("recordingsStore", "filterStore")
@observer
class CamerasListContainer extends React.Component<EventsProps, {}> {
  scrollParentRef: HTMLDivElement | null = null;

  state = {
    listHeight: 500,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  updateEventsSize = () => {
    this.setState({ listHeight: document.body.clientHeight - 250 });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateEventsSize);
    this.updateEventsSize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateEventsSize);
  }

  render() {
    const { recordingsStore } = this.injected;

    return (
      <>
        {!recordingsStore.loading && !recordingsStore.records.length && (
          <Typography color="textPrimary">No events</Typography>
        )}
        {recordingsStore.loading && !recordingsStore.records.length ? (
          <CircularProgress />
        ) : (
          <InfiniteScroll
            loadMore={() => recordingsStore.loadAllRecords()}
            hasMore={
              recordingsStore.records.length ? !!recordingsStore.next : false
            }
            loader={<CircularProgress />}
            useWindow={false}
          >
            <CamerasList />
          </InfiniteScroll>
        )}
      </>
    );
  }
}

export default withStyles(styles)(CamerasListContainer);
