import React from 'react';
import { observer } from 'mobx-react';
import { Button, useMediaQuery, useTheme } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useHistory } from 'react-router';
import { useStores } from '../../stores/RootStore';

function getMonthAndDate(timestamp: number): string {
  return new Date(timestamp).toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
  });
}

const ReportDownloader = observer((props) => {
  const { filterStore } = useStores();
  const history = useHistory();
  const dateFrom = getMonthAndDate(filterStore.startDateTimestamp);
  const dateTo = getMonthAndDate(filterStore.endDateTimestamp);
  const dateInterval = `${dateFrom}-${dateTo}`;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Button
      fullWidth
      onClick={() =>
        history.push({
          pathname: '/report',
          search: `period=${filterStore.startDateTimestamp}:${filterStore.endDateTimestamp}`,
        })
      }
      startIcon={<GetAppIcon />}
      variant='contained'
      color='primary'
      style={{ whiteSpace: 'nowrap' }}
    >
      {matches && dateInterval} Full Report
    </Button>
  );
});

export default ReportDownloader;
