import { flow, Instance, types } from 'mobx-state-tree';
import { withEnvironment } from './withEnvironment';
import { values } from 'mobx';
import {
  sortArrayOfObjectNumberProp,
  sortArrayOfObjectStringProp,
} from '../services/sort';
import { withStores } from './withStores';
import config from '../config';

// enum RecordClassification {
//   Unknown = 0,
//   Person = 1,
//   FalsePositive = 2,
//   FalseNegative = 3
// }

const SearchPanel = types
  .model({
    search: '',
  })
  .actions((self) => {
    function setSearch(newSearch) {
      self.search = newSearch;
    }

    return {
      setSearch,
    };
  });

const Flag = types.model({
  companyId: types.string,
  reason: types.string,
  userName: types.maybeNull(types.string),
});

const Recording = types
  .model({
    serial: types.string,
    label: types.string,
    description: types.string,
    classification: types.number,
    createdAt: types.number,
    startedAt: types.number,
    updatedAt: types.number,
    completedAt: types.maybeNull(types.number),
    duration: types.number,
    thumbnails: types.number,
    thumbnailsSrc: types.optional(types.array(types.string), []),
    segments: types.number,
    routeId: types.string,
    companyId: types.string, //TODO переделать на линку
    flag: types.maybeNull(Flag),
  })
  .actions((self) => ({
    addThumb(url) {
      self.thumbnailsSrc.push(url);
    },
    removeThumbs() {
      self.thumbnailsSrc.clear();
    },
  }));

export interface RecordingInterface extends Instance<typeof Recording> {}

export const RecordingsStore = types
  .model({
    loading: false,
    searchPanel: types.optional(SearchPanel, {}),
    items: types.optional(types.array(Recording), []),
    next: types.optional(types.string, ''),
  })
  .extend(withEnvironment)
  .extend(withStores)
  .views((self) => ({
    get records() {
      function sort(newSortBy: string, recs) {
        switch (newSortBy) {
          case 'activity':
            return sortArrayOfObjectStringProp(recs, 'description');
          case 'name':
            return sortArrayOfObjectStringProp(recs, 'label');
          default:
            return sortArrayOfObjectNumberProp(recs, 'createdAt');
        }
      }

      const recs = values(self.items).filter((item) => {
        if (!self.searchPanel.search) return true;
        return (
          item.label
            .toLowerCase()
            .indexOf(self.searchPanel.search.toLowerCase()) !== -1
        );
      });

      return self.filterStore.sortBy
        ? sort(self.filterStore.sortBy, recs)
        : recs;
    },
  }))
  .actions((self) => ({
    setItems(newItems: RecordingInterface[]) {
      self.items.clear();
      self.items.push(...newItems);
    },
    getItem(cameraId, recordingLabel) {
      return self.items.find(
        (recording) =>
          recording.serial === cameraId && recording.label === recordingLabel
      );
    },
  }))
  .actions((self) => ({
    loadThumbnails: function () {
      try {
        self.items.forEach((recording) => {
          if (!recording.thumbnailsSrc.length) {
            self.environment.api.client
              .get(
                `/recordings/${recording.companyId}/${recording.serial}/${recording.label}/thumbnails/json`
              )
              .then((response) => {
                const responseObj = (response.data || {}) as Object;
                Object.keys(responseObj).forEach((key) => {
                  if (key.endsWith('.json')) {
                    return;
                  }
                  recording.addThumb(responseObj[key]);
                });
              });
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
  }))
  .actions((self) => ({
    loadAllRecordsMock: flow(function* (reset = false) {
      self.loading = true;

      if (reset) {
        self.items.clear();
        self.next = '';
      }

      const route = `${config.ROOT_PATH}json/records.json`;
      // self.filterStore.camera ?
      //   `/recordings/camera/${self.filterStore.camera.serialNumber}` : self.filterStore.route ?
      //     `/recordings/route/${self.filterStore.route.routeId}` : '/recordings/';

      const resp = yield self.environment.api.client.get(route, {
        next: self.next || undefined,
        before: self.filterStore.endDate
          ? self.filterStore.endDateTimestamp
          : undefined,
        after: self.filterStore.startDate
          ? self.filterStore.startDateTimestamp
          : undefined,
        companyId: self.filterStore.company
          ? self.filterStore.company.companyId
          : undefined,
      });

      if (resp.ok) {
        if (self.next) {
          self.items.push(...(resp?.data?.items || []));
        } else {
          self.setItems(resp?.data?.items || []);
        }
        self.next = resp?.data?.next || '';
        // self.loadThumbnails();
      }

      self.loading = false;

      return resp;
    }),
    loadAllRecords: flow(function* (reset = false) {
      self.loading = true;

      if (reset) {
        self.items.clear();
        self.next = '';
      }

      const route = self.filterStore.camera
        ? `/recordings/camera/${self.filterStore.camera.serialNumber}`
        : self.filterStore.route
        ? `/recordings/route/${self.filterStore.route.routeId}`
        : '/recordings/';

      const resp = yield self.environment.api.client.get(route, {
        next: self.next || undefined,
        before: self.filterStore.endDate
          ? self.filterStore.endDateTimestamp
          : undefined,
        after: self.filterStore.startDate
          ? self.filterStore.startDateTimestamp
          : undefined,
        companyId: self.filterStore.company
          ? self.filterStore.company.companyId
          : undefined,
      });

      if (resp.ok) {
        if (self.next) {
          self.items.push(...(resp?.data?.items || []));
        } else {
          self.setItems(resp?.data?.items || []);
        }
        self.next = resp?.data?.next || '';
        self.loadThumbnails();
      }

      self.loading = false;

      return resp;
    }),
    addFlag: flow(function* (
      cameraId: string,
      recordingLabel: string,
      reason: string
    ) {
      const result = yield self.environment.api.client.post(
        `/recordings/camera/${cameraId}/flag/${recordingLabel}`,
        { reason }
      );

      if (result.ok) {
        // update recording in store
        const recording = self.getItem(cameraId, recordingLabel);
        if (recording) {
          recording.flag = {
            companyId: recording.companyId,
            reason: reason,
            userName: null,
          };
        }
      }
      return result;
    }),
    loadVideo: flow(function* (cameraId, recordingLabel) {
      return yield self.environment.api.client.get(
        `/recordings/camera/${cameraId}/${recordingLabel}`
      );
    }),
  }));

export interface RecordingsStoreInterface
  extends Instance<typeof RecordingsStore> {}
