import React, { useState } from "react";
import { observer } from "mobx-react";
// Material UI imports
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import SettingsIcon from "@material-ui/icons/Settings";
import { makeStyles, createStyles } from "@material-ui/core/styles";
// Other relative imports
import SettingsDialog from "../Settings/SettingsDialog";
import { CameraInterface } from "../../stores/cameraStore";

const useStyles = makeStyles(() =>
  createStyles({
    listItem: {
      height: "220px",
      position: "relative",
      padding: "0px",
      margin: "0px",
      background:
        "linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.4) 41.15%, rgba(0, 0, 0, 0) 100%)",
    },
    thumbnail: {
      width: "100%",
      height: "100%",
    },
    label: {
      position: "absolute",
      top: "4px",
      left: "8px",
      color: "#FFF",
      fontWeight: "bold",
    },
    iconWrapper: {
      position: "absolute",
      bottom: "12px",
      right: "12px",
      width: "28px",
      height: "28px",
      background: "#FFF",
      color: "rgba(60, 60, 67, 0.6)",
      borderRadius: "50%",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      width: "18px",
      height: "18px",
      cursor: "pointer",
    },
  })
);

interface ICamerasListItemProps {
  idx: number;
  camera: CameraInterface;
  onSettingsIconClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const CamerasListItem = ({
  idx,
  camera,
  onSettingsIconClick,
}: ICamerasListItemProps) => {
  const classes = useStyles();
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const thumbnailUrl = `${process.env.PUBLIC_URL}/mock-thumbnails/${idx % 10}.jpg`;

  return (
    <>
      <Box className={classes.listItem}>
          <img
            src={thumbnailUrl}
            alt={camera.cameraName}
            className={classes.thumbnail}
          />
        <Typography variant="body2" className={classes.label}>
          {camera.cameraName}
        </Typography>
        <div
          className={classes.iconWrapper}
          onClick={(e) => {
            setSettingsOpen(!!camera);
            onSettingsIconClick?.(e);
          }}
        >
          <SettingsIcon className={classes.icon} />
        </div>
      </Box>
      <SettingsDialog
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        thumbnailUrl={thumbnailUrl}
      />
    </>
  );
};

export default observer(CamerasListItem);
