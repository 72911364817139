import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { darkTheme } from 'services/theme';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { Panel } from 'components/Panel';
import config from '../../config';
import { Map } from '../../components/Map';

const styles = (theme: Theme) =>
  createStyles({
    content: {
      height: '100%',
      backgroundImage: `url(${config.ROOT_PATH}assets/map.jpg)`,
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      flexGrow: 1,
    },
  });

interface MapPageProps extends WithStyles<typeof styles>, RouteComponentProps {}

class MapPage extends React.Component<MapPageProps, {}> {
  render() {
    return (
      <ThemeProvider theme={darkTheme}>
        <Panel headerProps={{ variant: 'absolute', withBg: true }}>
          <Map />
        </Panel>
      </ThemeProvider>
    );
  }
}

export default withRouter(withStyles(styles)(MapPage));
