import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  createStyles,
  debounce,
  InputAdornment,
  Menu,
  MenuItem,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Business, Dashboard } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import SortIcon from '@material-ui/icons/Sort';
import { darkTheme } from 'services/theme';

import { CompanyInterface, RouteInterface } from 'stores/companyStore';
import { AuthStoreInterface } from 'stores/authStore';
import { FilterStoreInterface } from 'stores/filterStore';
import { CameraInterface } from 'stores/cameraStore';
import { SortTypeToText } from 'components/DEvents/consts';
import IButton from 'components/IButton';
import { NioIcon } from 'components/icons/NioIcon';

const styles = (theme: Theme) =>
  createStyles({
    searchPanel: {
      color: '#fff',
      background: darkTheme.palette.background.paper,
    },
    searchRoot: {
      flex: '1',
    },
    searchInputProps: {
      padding: '5.5px 14px',
      fontSize: '1rem',
    },
    button: {
      minWidth: 245,
      [theme.breakpoints.down('md')]: {
        minWidth: 155,
      },
    },
    dropDownContainer: {
      padding: 0,
    },
    menu: {
      minWidth: 245,
      maxHeight: '25vh',
      [theme.breakpoints.down('md')]: {
        minWidth: 175,
      },
    },
    menuSmall: {
      minWidth: 121,
    },
  });

interface PanelFilterProps extends WithStyles<typeof styles> {
  placeholder: string;
  searchChange: (search: string) => void;
}

interface PanelFilterState {
  searchClasses: {
    root: string;
  };
  searchInputProps: { classes: { input: string }; startAdornment: any };
  sectorMenu: null | HTMLElement;
  companyMenu: null | HTMLElement;
  cameraMenu: null | HTMLElement;
  sortMenu: null | HTMLElement;
}

interface InjectedProps extends PanelFilterProps {
  authStore: AuthStoreInterface;
  filterStore: FilterStoreInterface;
}

@inject('authStore', 'filterStore')
@observer
class PanelFilter extends React.Component<PanelFilterProps, PanelFilterState> {
  constructor(props) {
    super(props);

    this.state = {
      searchClasses: {
        root: props.classes.searchRoot,
      },
      searchInputProps: {
        classes: {
          input: props.classes.searchInputProps,
        },
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
      },
      sectorMenu: null,
      sortMenu: null,
      companyMenu: null,
      cameraMenu: null,
    };
  }

  componentDidMount() {
    const { authStore, filterStore } = this.props as InjectedProps;

    // filterStore.getCameras();
    filterStore.getCamerasMock();
    if (authStore.isSPUser) {
      // filterStore.getCompanies();
      filterStore.getCompaniesMock();
    }
    if (!authStore.currentCompany) {
      // authStore.getCurrentCompany();
      authStore.getCurrentCompanyMock();
    }
  }

  handleSortChanged = (sortBy) => {
    const { filterStore } = this.props as InjectedProps;
    filterStore.setSortBy(sortBy);
    this.handleSortMenuClose();
  };

  throttleSearchChange = debounce((value: string) => {
    this.props.searchChange(value);
  }, 700);

  handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.throttleSearchChange(e.target.value);
  };

  handleSectorMenuClose = () => {
    this.setState({ sectorMenu: null });
  };

  handleSortMenuClose = () => {
    this.setState({ sortMenu: null });
  };

  handleCompanyMenuClose = () => {
    this.setState({ companyMenu: null });
  };

  handleCameraMenuClose = () => {
    this.setState({ cameraMenu: null });
  };

  selectSector = (route: RouteInterface | null) => {
    const { filterStore } = this.props as InjectedProps;
    filterStore.setRoute(route);

    // update related company list
    filterStore.getCompanies();
    this.selectCompany(null);

    this.handleSectorMenuClose();
  };

  drawSortButton = () => {
    const { classes, filterStore } = this.props as InjectedProps;
    const { sortMenu } = this.state;

    return (
      <>
        <Grid item sm>
          <Grid
            container
            justify='flex-end'
            classes={{ root: classes.dropDownContainer }}
          >
            <IButton
              style={{ minWidth: 'auto' }}
              startIcon={<SortIcon />}
              onClick={(event) =>
                this.setState({ sortMenu: event.currentTarget })
              }
            >
              {filterStore.sortBy
                ? SortTypeToText[filterStore.sortBy]
                : 'Sort by'}
            </IButton>
            <Menu
              anchorEl={sortMenu}
              keepMounted
              open={Boolean(sortMenu)}
              onClose={this.handleSortMenuClose}
              classes={{ paper: classes.menuSmall }}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {Object.keys(SortTypeToText).map((sort, key) => (
                <MenuItem
                  key={key}
                  onClick={() => this.handleSortChanged(sort)}
                >
                  {SortTypeToText[sort]}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>
      </>
    );
  };

  selectCompany = (company: CompanyInterface | null) => {
    const { filterStore } = this.props as InjectedProps;
    filterStore.setCompany(company);

    // update related camera
    this.selectCamera(null);
    filterStore.getCameras();

    this.handleCompanyMenuClose();
  };

  drawCompanyButton = () => {
    const { filterStore, classes } = this.props as InjectedProps;
    const { companyMenu } = this.state;

    // return (<>{!!filterStore.companies.length && <Grid item>
    return (
      <>
        {
          <Grid item>
            <IButton
              startIcon={<Business />}
              onClick={(event) =>
                this.setState({ companyMenu: event.currentTarget })
              }
              className={classes.button}
            >
              {filterStore.company
                ? filterStore.company.companyName
                : 'All companies'}
            </IButton>
            <Menu
              anchorEl={companyMenu}
              keepMounted
              open={Boolean(companyMenu)}
              onClose={this.handleCompanyMenuClose}
              classes={{ paper: classes.menu }}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuItem onClick={() => this.selectCompany(null)}>
                All companies
              </MenuItem>
              {filterStore.companies.map((company, key) => (
                <MenuItem key={key} onClick={() => this.selectCompany(company)}>
                  {company.companyName}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        }
      </>
    );
  };

  selectCamera = (camera: CameraInterface | null) => {
    const { filterStore } = this.props as InjectedProps;
    filterStore.setCamera(camera);

    this.handleCameraMenuClose();
  };

  drawCameraButton = () => {
    const { filterStore, classes } = this.props as InjectedProps;
    const { cameraMenu } = this.state;

    // return (<>{!!filterStore.cameras.length && <Grid item>
    return (
      <>
        {
          <Grid item>
            <IButton
              startIcon={<NioIcon />}
              onClick={(event) =>
                this.setState({ cameraMenu: event.currentTarget })
              }
              className={classes.button}
            >
              {filterStore.camera ? filterStore.camera.cameraName : 'All Niōs'}
            </IButton>
            <Menu
              anchorEl={cameraMenu}
              keepMounted
              open={Boolean(cameraMenu)}
              onClose={this.handleCameraMenuClose}
              classes={{ paper: classes.menu }}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuItem onClick={() => this.selectCamera(null)}>
                All cameras
              </MenuItem>
              {filterStore.cameras.map((camera, key) => (
                <MenuItem key={key} onClick={() => this.selectCamera(camera)}>
                  {camera.cameraName}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        }
      </>
    );
  };

  drawSectorButton = () => {
    // const { classes } = this.props;
    const { filterStore, authStore, classes } = this.props as InjectedProps;
    const { sectorMenu } = this.state;

    const routesToSelect = authStore.currentCompany
      ? authStore.currentCompany.routes || []
      : ([] as RouteInterface[]);

    // return (<>{!!routesToSelect.length && <Grid item>
    return (
      <>
        {
          <Grid item>
            <IButton
              startIcon={<Dashboard />}
              onClick={(event) =>
                this.setState({ sectorMenu: event.currentTarget })
              }
              className={classes.button}
            >
              {filterStore.route ? filterStore.route.routeName : 'All sectors'}
            </IButton>
            <Menu
              anchorEl={sectorMenu}
              keepMounted
              open={Boolean(sectorMenu)}
              onClose={this.handleSectorMenuClose}
              classes={{ paper: classes.menu }}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuItem onClick={() => this.selectSector(null)}>
                All sectors
              </MenuItem>
              {routesToSelect.map((route, key) => (
                <MenuItem key={key} onClick={() => this.selectSector(route)}>
                  {route.routeName}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        }
      </>
    );
  };

  render() {
    // const { authStore } = this.props as InjectedProps;

    return (
      <Grid container spacing={1}>
        {/* {authStore.isSPUser && this.drawSectorButton()} */}
        {this.drawSectorButton()}
        {/* {authStore.isSPUser && this.drawCompanyButton()} */}
        {this.drawCompanyButton()}

        {this.drawCameraButton()}

        {this.drawSortButton()}
      </Grid>
    );
  }
}

export default withStyles(styles)(PanelFilter);
