import React, { useRef } from 'react';
import { BarChart, Bar, XAxis } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import { useStores } from '../../stores/RootStore';

import { colors } from '../../services/colors';
import { svgToPng } from '../../services/svgToPng';

const data = [
  {
    name: '12',
    pv: 2400,
  },
  {
    name: '1',
    pv: 1398,
  },
  {
    name: '2',
    pv: 9800,
  },
  {
    name: '3',
    pv: 3908,
  },
  {
    name: '4',
    pv: 4800,
  },
  {
    name: '6',
    pv: 3800,
  },
  {
    name: '7',
    pv: 4300,
  },
  {
    name: '8',
    pv: 4300,
  },
  {
    name: '9',
    pv: 4300,
  },
  {
    name: '10',
    pv: 4300,
  },
  {
    name: '11',
    pv: 4300,
  },
  {
    name: '12',
    pv: 2400,
  },
  {
    name: '1',
    pv: 1398,
  },
  {
    name: '2',
    pv: 9800,
  },
  {
    name: '3',
    pv: 3908,
  },
  {
    name: '4',
    pv: 4800,
  },
  {
    name: '6',
    pv: 3800,
  },
  {
    name: '7',
    pv: 4300,
  },
  {
    name: '8',
    pv: 4300,
  },
  {
    name: '9',
    pv: 4300,
  },
  {
    name: '10',
    pv: 4300,
  },
  {
    name: '11',
    pv: 4300,
  },
];

export const ActivityChartStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 45,
    marginBottom: 35,
  },
  periods: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  period: {
    width: '49.3%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  periodText: {
    fontSize: 12,
    fontWeight: 500,
    marginTop: 1,
    color: '#555555',
  },
  periodLine: {
    width: '100%',
    height: 1,
    backgroundColor: '#555555',
    margin: 0,
    marginTop: '-10px',
  },
});

export const ActivityChart = () => {
  const classes = ActivityChartStyles({});
  const { reportStore } = useStores();
  const { activityCharts } = reportStore;

  const container = useRef<HTMLDivElement>(null);
  const chartRef = useRef(null);

  const convertChart = async (ref) => {
    if (ref && ref.container) {
      const svg = ref.container.children[0];
      const pngData = await svgToPng(
        svg,
        container.current ? container.current.offsetWidth : 770,
        95
      );
      activityCharts?.setActivityChart(pngData);
    }
  };

  return (
    <div className={classes.container} ref={container}>
      <BarChart
        width={container.current?.offsetWidth}
        height={95}
        data={data}
        barSize={7}
        ref={chartRef}
      >
        <XAxis
          dataKey='name'
          axisLine={false}
          tickLine={false}
          padding={{ left: 10, right: 10 }}
          tick={{ fontFamily: 'Barlow', fontWeight: 500, fontSize: 12 }}
        />
        <Bar
          dataKey='pv'
          fill='#000000'
          background={{ fill: colors.gray_light, radius: 5 }}
          radius={5}
          stroke='#fff'
          strokeWidth={2}
          animationDuration={0}
          onAnimationEnd={() => {
            convertChart(chartRef.current);
          }}
        />
      </BarChart>
      <div className={classes.periods}>
        <div className={classes.period}>
          <hr className={classes.periodLine} />
          <span className={classes.periodText}>AM</span>
        </div>
        <div className={classes.period}>
          <hr className={classes.periodLine} />
          <span className={classes.periodText}>PM</span>
        </div>
      </div>
    </div>
  );
};
