import { types, flow, getEnv, Instance } from "mobx-state-tree";
import { camelizeKeys } from "humps";
import { Environment } from "../Environment";
import { DesiredSettings } from "./models/DesiredSettings";
import { ReqStatus } from "../../types/ReqStatus";

// Cameras state schema could be found here
// https://github.com/kuna-systems/commercial-device-state

export const CameraSettingsStore = types
  .model("CameraSettingsStore", {
    reqStatus: types.optional(
      types.enumeration<ReqStatus>(Object.values(ReqStatus)),
      ReqStatus.Idle
    ),
    companyId: types.maybeNull(types.string),
    cameraName: types.maybeNull(types.string),
    serialNumber: types.maybeNull(types.string),
    desired: types.optional(DesiredSettings, {}),
  })
  .actions((self) => {
    const env = getEnv<Environment>(self);
    const { api } = env;

    return {
      getCameraSettings: flow(function* (companyId: string, cameraId: string) {
        self.reqStatus = ReqStatus.Loading;

        const response =
          yield api.getCameraSettingsMock(companyId, cameraId);
        self.reqStatus = ReqStatus.Succeeded;

        self.companyId = response.companyId;
        self.cameraName = response.cameraName;
        self.serialNumber = response.serialNumber;
        self.desired =
          response.desired &&
          DesiredSettings.create(
            camelizeKeys(JSON.parse(response.desired)),
            env
          );
      }),
    };
  });

export interface ICameraSettingsStore
  extends Instance<typeof CameraSettingsStore> {}
