/**
 * Sorts an array of objects by its string property.
 */
export function sortArrayOfObjectStringProp<T extends Record<string, any>, A extends T[], P extends keyof T>(array: A, prop: P) {
  return  array.sort((a, b) => {
    const nameA = a[prop].toUpperCase();
    const nameB = b[prop].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  })
}

/**
 * Sorts an array of objects by its number property.
 */
export function sortArrayOfObjectNumberProp<T extends Record<string, any>, A extends T[], P extends keyof T>(array: A, prop: P) {
  return  array.sort((a, b) => {
    const valueA = a[prop];
    const valueB = b[prop];
    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }

    return 0;
  })
}
