import {Instance, types} from 'mobx-state-tree';


export const Route = types.model({
  routeName: types.optional(types.string, ''),
  topicArn: types.optional(types.string, ''),
  routeId: types.optional(types.identifier, ''),
});

export interface RouteInterface extends Instance<typeof Route> {
}


export const Company = types.model({
  companyName: types.optional(types.string, ''),
  routeId: types.optional(types.string, ''),
  topicArn: types.optional(types.string, ''),
  companyId: types.identifier,
  address: types.optional(types.maybeNull(types.string), ''),
  routes: types.optional(types.maybeNull(types.array(Route)), []),
});

export interface CompanyInterface extends Instance<typeof Company> {
}
