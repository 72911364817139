import React, { useState, useEffect, ChangeEvent } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeUp from "@material-ui/icons/VolumeUp";
import Box from "@material-ui/core/Box";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    thumb: {
      height: 20,
      width: 20,
      marginTop: -9,
      marginLeft: -11,
    },
  })
);

export interface IVolumeProps {
  value?: number;
  onChange?: (event: ChangeEvent<{}>, value: number | number[]) => void;
}

export const Volume = ({ value = 0, onChange }: IVolumeProps) => {
  const [sliderValue, setSliderValue] = useState<number>(value);

  useEffect(() => {
    setSliderValue(value);
  }, [value]);

  return (
    <Box width="100%">
      <Typography variant="body2" gutterBottom>
        Volume
      </Typography>
      <Grid container spacing={2}>
        <Grid item>
          <VolumeDown />
        </Grid>
        <Grid item xs>
          <Slider
            value={sliderValue}
            onChange={(_, newValue) => setSliderValue(newValue as number)}
            onChangeCommitted={onChange}
            aria-labelledby="continuous-slider"
            classes={useStyles()}
          />
        </Grid>
        <Grid item>
          <VolumeUp />
        </Grid>
      </Grid>
    </Box>
  );
};
