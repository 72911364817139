import React, { useCallback, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { calcDuration, getInfoText } from "services/videoItemUtils";
import { RecordingInterface } from "stores/recordingsStore";
import { RecordDescription } from "./consts";
import { DescriptionIcon } from "../DescriptionIcon";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import FlagIcon from "@material-ui/icons/Flag";
import moment from "moment";
import { Tooltip } from "@material-ui/core";

const EventsListItemStyles = makeStyles((theme) => ({
  videoWrapper: {
    position: "relative",
    overflow: "hidden",
    minWidth: 200,
    height: 150,
    marginBottom: 6,
    background: "#C4C4C4",
    borderRadius: 3,
    [theme.breakpoints.up("lg")]: {
      height: 220,
    },
    "@media (width: 1280px)": {
      height: 150,
    },
  },
  previewImg: {
    height: "100%",
    width: "100%",
  },
  overlay: {
    cursor: "pointer",
    position: "absolute",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.4) 41.15%, rgba(0, 0, 0, 0) 100%)",
    padding: "0px 8px 8px 8px",
  },
  title: {
    position: "relative",
    width: "100%",
    textOverflow: "initial",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 15,
    lineHeight: 1.47,
    letterSpacing: "-0.408px",
    "-webkit-text-stroke-width": "1px",
    "-webkit-text-stroke-color": "rgba(0, 0, 0, 0.5)",
  },
  serviceInfo: {
    display: "flex",
    alignItems: "flex-end",
  },
  serviceIconWrapper: {
    width: 28,
    padding: 5,
    background: "#fff",
    borderRadius: 14,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "rgba(60, 60, 67, 0.6)",
  },
  duration: {
    fontWeight: 600,
    fontSize: 13,
    marginLeft: 11,
    lineHeight: 1.38,
    letterSpacing: "-0.08px",
    "-webkit-text-stroke-width": "1px",
    "-webkit-text-stroke-color": "rgba(0, 0, 0, 0.5)",
  },
  infoText: {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: 1.69,
    color: "#979797",
  },
  infoDateTime: {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: 1.69,
  },
}));

interface Props extends RecordingInterface {
  idx: number;
}

function EventsListItem({
  thumbnailsSrc,
  label,
  description,
  duration,
  startedAt,
  flag,
  idx,
}: Props) {
  const [intervalID, setIntervalID] = useState<number | null>();
  const [preview, setPreview] = useState("");
  const classes = EventsListItemStyles();

  useEffect(() => {
    return () => {
      intervalID && window.clearInterval(intervalID);
    };
  }, [intervalID]);

  useEffect(() => {
    thumbnailsSrc.length && setPreview(thumbnailsSrc[0]);
  }, [thumbnailsSrc.length, thumbnailsSrc]);

  const handleListHoverStar = useCallback(() => {
    if (intervalID) return;
    let index = 0;
    const id = window.setInterval(() => {
      if (index < thumbnailsSrc.length) {
        setPreview(thumbnailsSrc[index]);
        ++index;
        return;
      }
      index = 0;
    }, 600);
    setIntervalID(id);
  }, [intervalID, thumbnailsSrc]);

  const handleListHoverEnd = () => {
    intervalID && window.clearInterval(intervalID);
    setIntervalID(null);
    setPreview(thumbnailsSrc.length ? thumbnailsSrc[0] : "");
  };

  return (
    <Grid container direction="column">
      <div
        className={classes.videoWrapper}
        onMouseEnter={handleListHoverStar}
        onMouseLeave={handleListHoverEnd}
      >
        <img
          className={classes.previewImg}
          src={`${process.env.PUBLIC_URL}/mock-thumbnails/${idx % 10}.jpg`}
          alt={label}
        />
        <div className={classes.overlay} data-itemlabel={label}>
          <Typography
            classes={{ root: classes.title }}
            data-itemlabel={label}
            noWrap
            variant="body2"
            color="textSecondary"
          >
            {label}
          </Typography>
          <div className={classes.serviceInfo}>
            <div className={classes.serviceIconWrapper}>
              <DescriptionIcon description={description as RecordDescription} />
            </div>
            <Typography
              classes={{ root: classes.duration }}
              variant="body2"
              color="textSecondary"
            >
              {calcDuration(duration)}
            </Typography>
          </div>
        </div>
      </div>
      <Grid container justify="space-between" item sm>
        <Grid item className={classes.infoText}>
          <Typography variant="inherit" color="inherit">
            {getInfoText(description as RecordDescription)}
          </Typography>
        </Grid>
        <Grid item>
          {!!flag && (
            <Tooltip title={flag.reason} aria-label={flag.reason}>
              <FlagIcon color="secondary" />
            </Tooltip>
          )}
        </Grid>
        <Grid item className={classes.infoDateTime}>
          <Typography variant="inherit" color="textPrimary">
            {moment(startedAt).format("L, LTS")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default observer(EventsListItem);
