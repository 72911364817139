import { Instance, types } from "mobx-state-tree";

// state - only "scheduled" (=on) and always_off (=off) are used

export const convertLightStateToBool = (value) => value === "always_off" ? false : true;

const AmbientLight = types.model({
  state: types.optional(
    types.enumeration(["always_on", "always_off", "scheduled"]),
    "scheduled"
  ),
  luminance: types.optional(types.number, 0.5),
  colorTemperature: types.optional(types.number, 0.5),
});

const PoliceLight = types.model({
  enabled: types.optional(types.boolean, true),
  redLuminance: types.optional(types.number, 0.5),
  blueLuminance: types.optional(types.number, 0.5),
});

const StatusLight = types.model({
  enabled: types.optional(types.boolean, true),
  luminance: types.optional(types.number, 1),
});

export const LightsSettings = types
  .model({
    ambientTop: types.optional(AmbientLight, {}),
    ambientBottom: types.optional(AmbientLight, {}),
    police: types.optional(PoliceLight, {}),
    status: types.optional(StatusLight, {}),
  })
  .actions((self) => ({
    toggleTopLights() {
      if (self.ambientTop.state === "always_off") {
        self.ambientTop.state = "scheduled";
      } else if (self.ambientTop.state === "scheduled") {
        self.ambientTop.state = "always_off";
      }
    },
    toggleBottomLights() {
      if (self.ambientBottom.state === "always_off") {
        self.ambientBottom.state = "scheduled";
      } else if (self.ambientBottom.state === "scheduled") {
        self.ambientBottom.state = "always_off";
      }
    },
    setBrightness(value: number) {
      self.ambientTop.luminance = value;
      self.ambientBottom.luminance = value;
    },
    setColorTemperature(value: number) {
      self.ambientTop.colorTemperature = value;
      self.ambientBottom.colorTemperature = value;
    }
  }));

export interface ILightsSettings extends Instance<typeof LightsSettings> {}
