import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  AppBar,
  Slide,
} from '@material-ui/core';
import { useStores } from 'stores/RootStore';

import PanelTop from './PanelHeaderTop';
import PanelBottom from './PanelHeaderBottom';
import PanelMiddle from './PanelHeaderMiddle';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: 12,
    },
    actions: {
      width: '100%',
      position: 'static',
      '&.absolute': {
        position: 'absolute',
        top: '100%',
        left: 0,
        '&:before': {
          content: '""',
          display: 'block',
          width: '100%',
          height: '123px',
          position: 'absolute',
          zIndex: -1,
          left: 0,
          top: 0,
          background:
            'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
          backdropFilter: 'blur(5px)',
        },
      },
      '&.relative': {
        position: 'relative',
        top: 0,
        left: 0,
      },
    },
  });

interface PanelHeaderProps extends WithStyles<typeof styles> {
  variant?: 'absolute' | 'relative' | 'static';
  withBg?: boolean;
  contentRef?: any;
}

function PanelHeader(props: PanelHeaderProps) {
  const { classes, variant, withBg, contentRef } = props;

  const { reportStore, authStore, filterStore } = useStores();

  const { loadStats } = reportStore;

  const [scroll, setScroll] = React.useState(0);

  useEffect(() => {
    if (contentRef?.current) {
      contentRef.current.addEventListener('scroll', () => {
        setScroll(contentRef.current.scrollTop);
      });
    }
  }, [contentRef]);

  useEffect(() => {
    loadStats(authStore.poolInfo?.identityPoolId);
  }, [
    loadStats,
    authStore,
    filterStore.startDate,
    filterStore.startDate,
    filterStore.company,
    filterStore.route,
    filterStore.camera,
  ]);

  return (
    <Slide appear={false} direction='down' in={scroll < 90}>
      <AppBar
        position='relative'
        color='transparent'
        className={classes.root}
        style={scroll < 93 ? {} : { position: 'absolute' }}
      >
        <PanelTop withBg={withBg} />

        <div className={clsx(classes.actions, { [`${variant}`]: variant })}>
          <PanelMiddle />
          <PanelBottom />
        </div>
      </AppBar>
    </Slide>
  );
}

export default observer(withStyles(styles)(PanelHeader));
