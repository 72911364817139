import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import clsx from 'clsx';
import { darkTheme } from 'services/theme';
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  Grid,
  Drawer,
} from '@material-ui/core';
import { SidebarStoreInterface } from 'stores/sidebarStore';
import PanelHeader from './PanelHeader';
import PanelSidebar from './PanelSidebar';

const drawerWidth = 'calc(100vw / 4 + 6px)';
const drawerWidthSmall = 'calc(100vw / 3)';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      background: darkTheme.palette.background.default,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    drawer: {
      zIndex: 9,
      width: drawerWidth,
      flexShrink: 0,
      [theme.breakpoints.down('md')]: {
        width: drawerWidthSmall,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      '&::-webkit-scrollbar': {
        width: '8px',
        height: '100%',
        backgroundColor: '#212121',
        zIndex: 2000,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#3E3E3E',
        borderRadius: '5px',
      },
      [theme.breakpoints.down('md')]: {
        width: drawerWidthSmall,
      },
    },
    content: {
      position: 'relative',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShift: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: '75%',
      [theme.breakpoints.down('md')]: {
        width: '66.66%',
      },
    },
    contentInner: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: -17,
      overflowX: 'hidden',
      overflowY: 'auto',
      boxSizing: 'content-box',
    },
  });

interface PanelProps extends WithStyles<typeof styles>, RouteComponentProps {
  headerProps?: object;
  sidebarStore?: SidebarStoreInterface;
}

@inject('sidebarStore')
@observer
class Panel extends React.Component<PanelProps, {}> {
  private contentRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
  }

  render() {
    const { children, classes, sidebarStore, headerProps } = this.props;

    return (
      <Grid component='main' className={classes.root}>
        <PanelHeader {...headerProps} contentRef={this.contentRef} />

        <div
          className={clsx(classes.content, {
            [classes.contentShift]: sidebarStore?.isOpen,
          })}
        >
          <div className={classes.contentInner} ref={this.contentRef}>
            {children}
          </div>
        </div>

        <Drawer
          className={classes.drawer}
          variant='persistent'
          anchor='right'
          open={sidebarStore?.isOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <PanelSidebar contentRef={this.contentRef} />
        </Drawer>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(Panel));
