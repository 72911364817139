import { flow, Instance, types } from 'mobx-state-tree';
import { withEnvironment } from './withEnvironment';
import { endOfDay, endOfWeek, format, startOfDay, startOfWeek } from 'date-fns';
import {
  Company,
  CompanyInterface,
  Route,
  RouteInterface,
} from './companyStore';
import { Camera, CameraInterface } from './cameraStore';
import config from '../config';

export const FilterStore = types
  .model({
    startDate: types.optional(types.Date, startOfWeek(new Date())),
    endDate: types.optional(types.Date, endOfWeek(new Date())),
    sortBy: types.maybeNull(types.string),
    route: types.optional(
      types.maybeNull(types.safeReference(Route)),
      undefined
    ),
    company: types.maybeNull(types.safeReference(Company)),
    companies: types.optional(types.array(Company), []),
    camera: types.maybeNull(types.safeReference(Camera)),
    cameras: types.optional(types.array(Camera), []),
  })
  .extend(withEnvironment)
  .views((self) => ({
    get startDateString() {
      return format(self.startDate, 'yyyyMMdd');
    },
    get endDateString() {
      return format(self.endDate, 'yyyyMMdd');
    },
    get startDateUTC() {
      return (
        +startOfDay(self.startDate) -
        self.startDate.getTimezoneOffset() * 60 * 1000
      );
    },
    get endDateUTC() {
      return (
        +endOfDay(self.endDate) - self.endDate.getTimezoneOffset() * 60 * 1000
      );
    },
    get startDateTimestamp() {
      return +startOfDay(self.startDate);
    },
    get endDateTimestamp() {
      return +endOfDay(self.endDate);
    },
  }))
  .actions((self) => ({
    setRange: function (startDate, endDate) {
      // console.log(startDate, endDate);

      self.startDate = startDate;
      self.endDate = endDate;
    },
    setSortBy: function (sort) {
      self.sortBy = sort;
    },
    setRoute: (route: RouteInterface | null) => {
      self.route = route;
    },
    setCompany: (company: CompanyInterface | null) => {
      self.company = company;
    },
    setCamera: (camera: CameraInterface | null) => {
      self.camera = camera;
    },
    getCompanies: flow(function* () {
      const response = yield self.environment.api.client.get(`v1/companies`, {
        routeId: self.route ? self.route.routeId : undefined,
      });

      if (response.ok) {
        self.companies = response.data['companies'];
        return response.data;
      }
    }),
    getCameras: flow(function* () {
      const response = yield self.environment.api.client.get(`v1/cameras/`, {
        companyId: self.company ? self.company.companyId : undefined,
      });

      if (response.ok) {
        self.cameras = response.data['cameras'];
        return response.data;
      }
    }),
    getCompaniesMock: flow(function* () {
      const response = yield self.environment.api.client.get(
        `${config.ROOT_PATH}json/companies.json`,
        { routeId: self.route ? self.route.routeId : undefined }
      );

      if (response.ok) {
        self.companies = response.data['companies'];
        return response.data;
      }
    }),
    getCamerasMock: flow(function* () {
      const response = yield self.environment.api.client.get(
        `${config.ROOT_PATH}json/cameras.json`,
        { companyId: self.company ? self.company.companyId : undefined }
      );

      if (response.ok) {
        self.cameras = response.data['cameras'];
        return response.data;
      }
    }),
  }));

export interface FilterStoreInterface extends Instance<typeof FilterStore> {}
