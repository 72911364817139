import {Instance, types} from 'mobx-state-tree';

export const Camera = types.model({
  companyId: types.maybe(types.string),
  cameraName: types.optional(types.string, ""),
  serialNumber: types.identifier,
  thumbnailUrl: types.maybeNull(types.string),
});

export interface CameraInterface extends Instance<typeof Camera> {
}
