import React, { lazy, Suspense } from 'react';
import { Grid, Typography } from '@material-ui/core';
import EventsListItem from './EventsListItem';

import {
  RecordingInterface,
  RecordingsStoreInterface,
} from 'stores/recordingsStore';
import { SidebarStoreInterface } from 'stores/sidebarStore';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const VideoModal = lazy(() => import('../VideoModal'));

interface Props extends RouteComponentProps {
  list: Array<RecordingInterface>;
  title?: string;
  recordingsStore?: RecordingsStoreInterface;
  sidebarStore?: SidebarStoreInterface;
}

interface State {
  selectedItem: RecordingInterface | null;
}

@inject('recordingsStore', 'sidebarStore')
@observer
class EventsList extends React.Component<Props, State> {
  state = {
    selectedItem: null,
  };

  async componentDidMount() {
    const { recordingsStore } = this.props;

    const searchParams = new URLSearchParams(this.props.location.search);
    const label = searchParams.get('label');
    const serial = searchParams.get('serial');

    if (label && serial) {
      const response = await recordingsStore?.loadVideo(serial, label);
      if (response.ok) {
        this.setState({ selectedItem: response.data });
      }
    }
  }

  handleCloseModal = () => {
    this.setState({ selectedItem: null });
    this.props.history.push(`/dashboard`);
  };

  handleItemClick = (e: React.MouseEvent<HTMLElement>) => {
    //@ts-ignore
    const label = e.target.dataset.itemlabel;
    const item = label && this.props.list.find((item) => item.label === label);
    if (item == null) {
      return;
    }

    this.props.history.push(
      `/dashboard?label=${item.label}&serial=${item.serial}`
    );

    this.setState({ selectedItem: item });
  };

  render() {
    let { list, title, sidebarStore } = this.props;
    let { selectedItem } = this.state;

    return (
      <Grid container>
        {title && (
          <Grid item sm={12}>
            <Typography component='h2' variant='h6' color='textPrimary'>
              {title}
            </Typography>
          </Grid>
        )}
        <Grid container spacing={3} item onClick={this.handleItemClick}>
          {list.map((video: RecordingInterface, i: number) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={sidebarStore?.isOpen ? 6 : 4}
                lg={sidebarStore?.isOpen ? 4 : 3}
                xl={sidebarStore?.isOpen ? 4 : 3}
                key={video.label}
              >
                <EventsListItem {...video} key={`${video.serial}_${video.createdAt}`} idx={i} />
              </Grid>
            );
          })}
        </Grid>
        <Suspense fallback={<div>Loading...</div>}>
          {selectedItem && (
            <VideoModal
              closeModal={this.handleCloseModal}
              video={selectedItem}
            />
          )}
        </Suspense>
      </Grid>
    );
  }
}

export default withRouter(EventsList);
