export const colors = {
  mainBlue: "#007AFF",
  green: "#00d770",
  darkGreen: "#00361c",
  gray: "#aaaaaa",
  gray_light: "#EEEEEE",
  yellow: "#ffe600",
  red: "#ff0046",
  purple: "#8535AA",
  gray_50: "#505050"
};
