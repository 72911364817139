import React from 'react';
import { styled } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';

const StyledButton = styled(Button)({
  minWidth: 245,
  padding: '8px 24px',
  backgroundColor: '#3E3E3E',
  boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.25)',
  borderRadius: '20px',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '24px',
  justifyContent: 'flex-start',
});

export default function IButton(props: ButtonProps) {
  return <StyledButton {...props} />;
}
