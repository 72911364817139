import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  Grid,
  Box,
  Typography,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { useStores } from '../../stores/RootStore';
import EventsChart from './EventsChart';
import UpTimeChart from './UpTimeChart';
import ActivityTimeChart from './ActivityTimeChart';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexDirection: 'column',
    },
    row: {
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(3),
      },
      '&:after': {
        content: '""',
        display: 'block',
        width: '100%',
        height: 24,
        position: 'absolute',
        left: 0,
        bottom: 0,
        background:
          'linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(196, 196, 196, 0.12) 100%)',
      },
    },
    title: {
      fontFamily: 'Montserrat, Helvetica Neue, sans-serif',
      fontWeight: 600,
      fontSize: 24,
      lineHeight: '29px',
      color: '#F4F4F4',
    },
    mask: {
      width: 'calc(100vw / 4 - 3px)',
      height: 280,
      [theme.breakpoints.down('md')]: {
        // width: '100%',
        width: 'calc(100vw / 3 - 9px)',
        height: 260,
      },
      backgroundColor: '#000',
      position: 'fixed',
      zIndex: 2,
    },
  });

interface PanelSidebarProps extends WithStyles<typeof styles> {
  contentRef?: any;
}

function PanelSidebar(props: PanelSidebarProps) {
  const { classes, contentRef } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const { reportStore, authStore, filterStore } = useStores();

  const [scroll, setScroll] = useState(0);
  const [paddingTop, setPaddingTop] = useState(0);

  const { loadStatsMock } = reportStore;
  useEffect(() => {
    loadStatsMock(authStore.poolInfo?.identityPoolId);
  }, [
    loadStatsMock,
    authStore,
    filterStore.startDate,
    filterStore.startDate,
    filterStore.company,
    filterStore.route,
    filterStore.camera,
  ]);

  useEffect(() => {
    if (contentRef?.current) {
      contentRef.current.addEventListener('scroll', () => {
        setScroll(contentRef.current.scrollTop);
      });
    }
  }, [contentRef]);

  useEffect(() => {
    if (scroll < 93) {
      matches ? setPaddingTop(294) : setPaddingTop(300);
    } else {
      setPaddingTop(15);
    }
  }, [matches, scroll]);

  return (
    <Grid container className={classes.root}>
      {scroll < 93 && <Box className={classes.mask} />}
      <Grid item style={{ paddingTop }}>
        <Box p={matches ? 2 : 3} className={classes.row}>
          {reportStore.loading ? (
            <Box m={4}>
              <CircularProgress />
            </Box>
          ) : (
            <UpTimeChart
              uptime={reportStore.stats?.workingHours}
              dowtime={reportStore.stats?.downtimeHours}
            />
          )}
        </Box>
      </Grid>
      <Grid item>
        <Box p={matches ? 2 : 3} className={classes.row}>
          {reportStore.loading ? (
            <Box m={4}>
              <CircularProgress />
            </Box>
          ) : (
            <EventsChart stats={reportStore.stats} />
          )}
        </Box>
      </Grid>
      <Grid item>
        <Box p={matches ? 2 : 3}>
          <Box mb={2}>
            <Typography className={classes.title}>Activity Time</Typography>
          </Box>
          <ActivityTimeChart />
        </Box>
      </Grid>
    </Grid>
  );
}

export default observer(withStyles(styles)(PanelSidebar));
