import { getRoot, IStateTreeNode } from 'mobx-state-tree';
import { RootStoreInterface } from './RootStore';
import { FilterStoreInterface } from './filterStore';
import { AuthStoreInterface } from './authStore';

export const withStores = (self: IStateTreeNode) => ({
  views: {
    get filterStore(): FilterStoreInterface {
      return getRoot<RootStoreInterface>(self).filterStore;
    },
    get authStore(): AuthStoreInterface {
      return getRoot<RootStoreInterface>(self).authStore;
    },
  },
});
