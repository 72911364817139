import makeInspectable from "mobx-devtools-mst";
import { Environment } from "./Environment";
import { RootStore } from "./RootStore";

export default function setupRootStore() {
  const env = new Environment();

  const rootStore = RootStore.create({}, env);

  makeInspectable(rootStore);

  try {
    const user = JSON.parse(localStorage.getItem("user") || "") || undefined;
    if (user) {
      // const userModel = rootStore.models.addUser(user);
      // rootStore.userStore.setUser(userModel);
    }
  } catch {
    console.warn("Unable to load user from local storage.");
  }

  return rootStore;
}
