import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface BoxedNumberProps {
  number: number;
  backgroundColor: string;
  textColor: string;
  width?: number;
}

export const BoxedNumberStyles = makeStyles<Theme, BoxedNumberProps>(theme => ({
  root: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'flex-end',
    borderRadius: 2,
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: ({ textColor }) => textColor,
    minWidth: ({ width }) => width,
    padding: '2px 4px',
    fontWeight: 700,
  },
}));

function BoxedNumber(props) {
  const classes = BoxedNumberStyles(props);
  const { number } = props;

  return (
    <span className={classes.root}>
      {number}
    </span>
  );
}

export default BoxedNumber;